import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import Aboutpage from "./Pages/Aboutpage";
import ProductDetailsPage from "./Pages/ProductDetailspage";
import Searchpage from "./Pages/Searchpage";
import Cartpage from "./Pages/Cartpage";
import CategoryPage from "./Pages/Categorypage";

const root = document.getElementById("root");

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const AppWrapper = () => {
  return (
    <React.StrictMode>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/about" element={<Aboutpage />} />
          <Route path="/search/:keyword" element={<Searchpage />} />
          <Route path="/product/:id" element={<ProductDetailsPage />} />
          <Route path="/cart" element={<Cartpage />} />
          <Route path="/category/:category" element={<CategoryPage />} />
        </Routes>
      </Router>
    </React.StrictMode>
  );
};

ReactDOM.render(<AppWrapper />, root);

reportWebVitals();
