import React, { useState, useEffect } from "react";
import { Card, Container, Row, Col, Image } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import kamera1 from "../assets/kamera1.png";
import lighting1 from "../assets/lighting1.png";
import Navigation from "../components/Navigation";
import Intro from "../components/Intro";
import Footer from "../components/Footer";
import id1 from "../assets/Camera_Canon_DSLR_5D_MarkIII.png";
import id2 from "../assets/Camera_Canon_DSLR_5D_MarkIV.png";
import id3 from "../assets/Camera_Sony_A7S_MarkII.png";
import id4 from "../assets/Camera_Sony_A7S_MarkIII.png";
import id5 from "../assets/Camera_Sony_FX3.png";
import id6 from "../assets/Camera_Sony_FX30.png";
import id7 from "../assets/Camera_Sony_PXW_FX6.png";
import id8 from "../assets/Camera_Red_Komodo.png";
import id9 from "../assets/Camera_GoPro.png";
import id12 from "../assets/Camera_Sony_A7_MarkIII.png";
import id13 from "../assets/Lensa_Compact_Prime_2.png";
import id14 from "../assets/Lensa_Canon_Cinemalens.png";
import id15 from "../assets/Lensa_Canon_SumirePrime.png";
import id16 from "../assets/Lensa_Canon_EF_8-15mm.png";
import id18 from "../assets/Lensa_Canon_EF_14mm_f2.8L.png";
import id19 from "../assets/Lensa_Canon_EF_16-35mm_f2.8L.png";
import id21 from "../assets/Lensa_Canon_EF_24mm_f1.4L_USM.png";
import id22 from "../assets/Lensa_Canon_EF_24-70mm_f2.8L.png";
import id23 from "../assets/Lensa_Canon_EF_24-105mm_f4L.png";
import id24 from "../assets/Lensa_Canon_EF_35mm_f1.4L.png";
import id25 from "../assets/Lensa_Canon_EF_50mm_f1.2L.png";
import id26 from "../assets/Lensa_Canon_EF_70-200mm_f2.8L.png";
import id27 from "../assets/Lensa_Canon_EF_85mm_f1.2L.png";
import id28 from "../assets/Lensa_Canon_EF_100mm_f2.8L.png";
import id29 from "../assets/Lensa_Canon_EF_100-400mm_f4.5-4.6L.png";
import id30 from "../assets/Lensa_Canon_EF_135mm_f2L.png";
import id31 from "../assets/Lensa_Sony_FE_12-24mm_f2.8.png";
import id32 from "../assets/Lensa_Sony_FE_14mm_f1.8.png";
import id33 from "../assets/Lensa_Sony_FE_16-35_f2.8.png";
import id34 from "../assets/Lensa_Sony_FE_24_f1.4.png";
import id35 from "../assets/Lensa_Sony_FE_24-70_f1.4.png";
import id36 from "../assets/Lensa_Sony_FE_35mm_f1.4.png";
import id37 from "../assets/Lensa_Sony_FE_50mm_f1.2.png";
import id38 from "../assets/Lensa_Sony_FE_70-200mm_f2.8.png";
import id39 from "../assets/Lensa_Sony_FE_85mm_f1.4.png";
import id40 from "../assets/Lensa_Sony_FE_100mm_f2.8_STF_GM_OSS.png";
import id41 from "../assets/Lensa_Sony_FE_100-400mm_f4.5-5.6_STF_GM_OS.png";
import id42 from "../assets/Lensa_Sony_135mm_f1.8.png";
import id44 from "../assets/Audio_Sennheiser_EW_100_G3_Wireless_Clip_On.png";
import id45 from "../assets/Audio_Sennheiser_EW_112P_G4_Wireless_Clip_On.png";
import id46 from "../assets/Audio_Sennheiser_SKP_100_G3_Plug_On_Transmitter.png";
import id47 from "../assets/Audio_Sennheiser_Boom_Mic_MKH-416.png";
import id49 from "../assets/Audio_Saramonic_Blink_500_B2_Wireless_Clip_On.png";
import id50 from "../assets/Audio_Saramonic_Blink_500_B4_Wireless_Clip_On.png";
import id51 from "../assets/Audio_Audio_Headphone.png";
import id52 from "../assets/Audio_Sound_Devices_552_Portable_5-Channel.png";
import id53 from "../assets/Audio_Recorder_Zoom_H6N__Basic.png";
import id54 from "../assets/Audio_Zoom_H8_8-Input_12-Track_Portable_Handy_Recorder.png";
import id55 from "../assets/Audio_Recorder_Zoom_F8_Multi_Track.png";
import id56 from "../assets/Audio_Rode_Videomic_Rycote.png";
import id57 from "../assets/Audio_Kabel_audio_XLR.png";
import id58 from "../assets/Audio_Hollyland_Solidcom__8_headset_+_1_Basic.png";
import id60 from "../assets/Lighting_ARRI_M_Series_M40_With_Ballast_High_Speed.png";
import id61 from "../assets/Lighting_ARRI_M_Series_M18_With_Ballast_High_Speed.png";
import id62 from "../assets/Lighting_ARRI_HMI_4000KW.png";
import id63 from "../assets/Lighting_ARRI_HMI_2500_Watt_Flicker_Free.png";
import id64 from "../assets/Lighting_Aputure_Amaran_F21C.png";
import id65 from "../assets/Lighting_Aputure_Amaran_F22C.png";
import id67 from "../assets/Lighting_Aputure_LS_300D_Mark_II__Kit.png";
import id68 from "../assets/Lighting_Aputure_LS_600D_Pro_(Kit).png";
import id69 from "../assets/Lighting_Aputure_LS_1200D.png";
import id70 from "../assets/Lighting_Aputure_NOVA_P600C_RGB.png";
import id71 from "../assets/Lighting_Aputure_Spotlight_19.png";
import id72 from "../assets/Lighting_Aputure_Fresnel.png";
import id73 from "../assets/Lighting_Aputure_Lantern.png";
import id74 from "../assets/Lighting_Godox_TL120_SET.png";
import id75 from "../assets/Lighting_Godox_TL60_SET.png";
import id76 from "../assets/Lighting_Godox_FL100_Flexible_LED_40x60_cm.png";
import id77 from "../assets/Lighting_Godox_FL150_Flexible_LED_30x120_cm.png";
import id80 from "../assets/Light_Acc_Frames.png";
import id81 from "../assets/Light_Acc_Cutterlight_Set.png";
import id82 from "../assets/Light_Acc_Overlength.png";
import id84 from "../assets/Light_Acc_Floppy.png";
import id85 from "../assets/Light_Acc_Tracing_Frame_+_Filter.png";
import id86 from "../assets/Light_Acc_Reflector.png";
import id87 from "../assets/Light_Acc_Sandbag.png";
import id89 from "../assets/Eq_Grip_Dji_Phantom_4.png";
import id90 from "../assets/Eq_Grip_DJI_Mavic_2_Pro__No_Pilot.png";
import id91 from "../assets/Eq_Grip_DJI_Mavic_3_Classic__No_Pilot.png";
import id92 from "../assets/Eq_Grip_DJI_Mavic_3_Cine_Premium__no_Pilot.png";
import id93 from "../assets/Eq_Grip_Dji_Ronin_RS2_Pro_Combo.png";
import id94 from "../assets/Eq_Grip_Dji_Ronin_RS3_Pro_Combo.png";
import id95 from "../assets/Eq_Grip_Zhiyun_Crane_3-Lab.png";
import id96 from "../assets/Eq_Grip_Portha_Jib__1.5_meter.png";
import id97 from "../assets/Eq_Grip_40_C-Stand.png";
import id98 from "../assets/Eq_Grip_20_C-Stand.png";
import id99 from "../assets/Eq_Grip_Magic_Arm.png";
import id100 from "../assets/Eq_Grip_Alligator_Clamp.png";
import id101 from "../assets/Eq_Grip_Butterfly_Set_6x6__Black_Cloth__Silk__Ultra_Bounce__Net____2pcs_C.png";
import id103 from "../assets/Eq_Grip_Slider_120Cm.png";
import id104 from "../assets/Eq_Grip_Applebox__Set_Of_5.png";
import id105 from "../assets/Eq_Grip_Tripod.png";
import id106 from "../assets/Eq_Grip_Monopod.png";
import id107 from "../assets/Eq_Grip_Statif_Light_Stand.png";
import id108 from "../assets/Eq_Grip_V-Mount_Battery.png";
import id109 from "../assets/Eq_Grip_Charger_V-Mount.png";
import id111 from "../assets/Eq_Monitor_Monitor_Director_CINEGEARS_Ruige_21.5_With_Case.png";
import id112 from "../assets/Eq_Monitor_Monitor_Director_Bestview_SP17_17_4K_Multi_View.png";
import id113 from "../assets/Eq_Monitor_FeelWorld_F5_5.0_Monitor__4K_Support_and_Tilt_Arm.png";
import id114 from "../assets/Eq_Monitor_Atomos_Ninja_V_5_4K_HDMI_Recording_Monitor__Include_SSD.png";
import id115 from "../assets/Eq_Monitor_Atomos_Shogun_Inferno_7_4K_HDMI_Quad_3G-SDI_12G-SDI_+_SSD.png";
import id116 from "../assets/Eq_Monitor_Monitor_Feelworld_Master_7_Ultra_Bright.png";
import id117 from "../assets/Eq_Monitor_Hollyland_Mars_4K_Wireless_Video_Transmission_System.png";
import id118 from "../assets/Eq_Monitor_Hollyland_Cosmo_C1_SDI_HDMI_Wireless_Video_Transmission_System.png";
import id119 from "../assets/Eq_Monitor_Hollyland_Mars_400S_PRO_SDI_HDMI_Wireless_Video_Transmission_System.png";
import id121 from "../assets/Eq_Add_Battery_Canon_LP-E6.png";
import id122 from "../assets/Eq_Add_Battery_Sony_NP-FZ100__For_Sony_A9__A7_III__A7R_III_series.png";
import id123 from "../assets/Eq_Add_Battery_Sony_NP-FW50.png";
import id124 from "../assets/Eq_Add_Battery_Sony_NP-F770.png";
import id125 from "../assets/Eq_Add_Charger_Canon_LC-E6_for_LP-E6.png";
import id126 from "../assets/Eq_Add_Charger_Sony_for_NP-FZ100.png";
import id127 from "../assets/Eq_Add_Charger_Sony_for_NP-FW50.png";
import id128 from "../assets/Eq_Add_Sandisk_MicroSD_Extreme_64gb.png";
import id129 from "../assets/Eq_Add_Sandisk_SDXC_EXTREME_64gb.png";
import id130 from "../assets/Eq_Add_CFexpress_Sony_80GB_Type_A_TOUGH.png";
import id131 from "../assets/Eq_Add_Sony_MRW-G2_CFexpress_Type_A_SD_Memory_Card_Reader.png";
import id132 from "../assets/Eq_Add_Cable_BNC__100_Meter.png";
import id134 from "../assets/Eq_Add_Cable_XLR__50_Meter.png";
import id135 from "../assets/Eq_Add_Cable_HDMI__10_Meter.png";
import id137 from "../assets/Eq_Add_ND_Filter.png";
import id138 from "../assets/Eq_Add_Step_Up_Ring.png";
import id139 from "../assets/Eq_Add_Mobil_Grandmax__Carry_+_BBM__Jakarta.png";

const Searchpage = () => {
  const [searchKeyword, setSearchKeyword] = useState("");
  const [cart, setCart] = useState([]);
  const location = useLocation();

  useEffect(() => {
    const savedCart = JSON.parse(localStorage.getItem("cart")) || [];
    setCart(savedCart);
  }, []);

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cart));
  }, [cart]);
  
  const products = [
    {
      id: 1,
      category: "Camera",
      image: [id1],
      title: "Canon DSLR 5D Mark III (Body Only)",
      include: "Include : Charger, Battery & Memory",
      price: "Rp350,000/day",
    },
    {
      id: 2,
      category: "Camera",
      image: [id2],
      title: "Canon DSLR 5D Mark IV (Body Only)",
      include: "Include : Charger, Battery & Memory",
      price: "Rp500,000/day",
    },
    {
      id: 12,
      category: "Camera",
      image: [id12],
      title: "Sony A7 Mark III (Body Only)",
      include: "Include : Charger, Battery & Memory",
      price: "Rp450,000/day",
    },
    {
      id: 3,
      category: "Camera",
      image: [id3],
      title: "Sony A7S Mark II (Body Only)",
      include: "Include : Charger, Battery & Memory",
      price: "Rp400,000/day",
    },
    {
      id: 4,
      category: "Camera",
      image: [id4],
      title: "Sony A7S Mark III (Body Only)",
      include: "Include : Charger, Battery & Memory",
      price: "Rp750,000/day",
    },
    {
      id: 5,
      category: "Camera",
      image: [id5],
      title: "Sony FX3 (Body Only)",
      include: "Include : Charger, Battery & Memory",
      price: "Rp850,000/day",
    },
    {
      id: 6,
      category: "Camera",
      image: [id6],
      title: "Sony FX30 (Body Only)",
      include: "Include : Charger, Battery & Memory",
      price: "Rp650,000/day",
    },
    {
      id: 7,
      category: "Camera",
      image: [id7],
      title: "Sony PXW-FX6 (Body Only)",
      include: "Include : Charger, Battery & Memory",
      price: "Rp1,250,000/day",
    },
    {
      id: 8,
      category: "Camera",
      image: [id8],
      title: "RED KOMODO (Non Lensa)",
      include: 'Matebox + Follow Focus 4x4, Filter ND (3,6,9), Filter Polarize, Monitor 5/7", Monitor 17/21", Tripod',
      price: "Rp4,000,000/day",
    },
    {
      id: 9,
      category: "Camera",
      image: [id9],
      title: "GoPro Hero 9 Black",
      include: "Include : Charger, Battery, Memory & Accessories",
      price: "Rp325,000/day",
    },
    {
      id: 10,
      category: "Camera",
      image: [id9],
      title: "GoPro Hero 10 Black",
      include: "Include : Charger, Battery, Memory & Accessories",
      price: "Rp350,000/day",
    },
    {
      id: 11,
      category: "Camera",
      image: [id9],
      title: "GoPro Hero 11 Black",
      include: "Include : Charger, Battery, Memory & Accessories",
      price: "Rp375,000/day",
    },
    {
      id: 13,
      category: "Lensa",
      image: [id13],
      title: "Lensa Compact Prime 2 (18, 25, 35, 50, 85) PL/EF",
      include: (
        <div>
            18mm T3.6,
            25mm T2.9,
            35mm T1.5 Super Speed,
            50mm T1.5 Super Speed,
            85mm T1.5 Super Speed
        </div>
    ),
    
      price: "Rp1,550,000/day",
    },
    {
      id: 14,
      category: "Lensa",
      image: [id14],
      title: "Lensa Canon Cinemalens (14,24,35,50,85,135)",
      include: (
        <div>
            Covers Full Frame & Super 35 Sensors,
            T1.5 Maximum Aperture,
            11-Blade Iris,
            Breathing Minimized Virtually to Zero,
            Dual Focus Markings,
            Stepless 300° Focus Rotation,
            Same Gear Positions as Other CN-E Models,
            Dust and Water Resistant Housing
        </div>
    ),
    
      price: "Rp1,550,000/day",
    },
    {
      id: 15,
      category: "Lensa",
      image: [id15],
      title: "Lensa Canon Sumire Prime (14,24,35,50,85,135) PL",
      include: (
        <div>
            6 Lensa Canon Sumire:
            <ul>
                <li>CN-E14mm T3.1 FP X</li>
                <li>CN-E20mm T1.5 FP X</li>
                <li>CN-E35mm T1.5 FP X</li>
                <li>CN-E50mm T1.3 FP X</li>
                <li>CN-E85mm T1.3 FP X</li>
                <li>CN-E135mm T2.2 FP X</li>
            </ul>
        </div>
    ),
    
      price: "Rp2,800,000/day",
    },
    {
      id: 16,
      category: "Lensa",
      image: [id16],
      title: "Lensa Canon EF 8-15mm f/4L USM (Fish Eye)",
      include: "Canon Lens EF 8-15 mm f/4L USM (Fish Eye)",
      price: "Rp300,000/day",
    },
    {
      id: 17,
      category: "Lensa",
      image: [kamera1],
      title: "Lensa Canon EF 11-24mm f/4L USM",
      include: "",
      price: "Rp475,000/day",
    },
    {
      id: 18,
      category: "Lensa",
      image: [id18],
      title: "Lensa Canon EF 14mm f/2.8L USM",
      include: "Canon Lens EF 14mm f2.8 L USM",
      price: "Rp300,000/day",
    },
    {
      id: 19,
      category: "Lensa",
      image: [id19],
      title: "Lensa Canon EF 16-35mm f/2.8L USM",
      include: "Canon Lens EF 16-35 mm f/2.8 L II USM",
      price: "Rp175,000/day",
    },
    {
      id: 20,
      category: "Lensa",
      image: [kamera1],
      title: "Lensa Canon EF 17-40mm f/4L USM",
      include: "",
      price: "Rp175,000/day",
    },
    {
      id: 21,
      category: "Lensa",
      image: [id21],
      title: "Lensa Canon EF 24mm f/1.4L USM",
      include: "The professional’s wide-angle lens with L-series optics, with a fast f/1.4 aperture, ideal for low light.",
      price: "Rp300,000/day",
    },
    {
      id: 22,
      category: "Lensa",
      image: [id22],
      title: "Lensa Canon EF 24-70mm f/2.8L USM",
      include: "Canon Lens EF 24-70mm f/2.8 L USM",
      price: "Rp175,000/day",
    },
    {
      id: 23,
      category: "Lensa",
      image: [id23],
      title: "Lensa Canon EF 24-105mm f/4L USM",
      include: "Canon Lens EF 24-105 mm f/4L USM",
      price: "Rp150,000/day",
    },
    {
      id: 24,
      category: "Lensa",
      image: [id24],
      title: "Lensa Canon EF 35mm f/1.4L USM",
      include: "Canon Lens Fix EF 35 mm f/1.4L USM",
      price: "Rp300,000/day",
    },
    {
      id: 25,
      category: "Lensa",
      image: [id25],
      title: "Lensa Canon EF 50mm f/1.2L USM",
      include: "Canon Lens Fix EF 50 mm f/1.2L USM",
      price: "Rp225,000/day",
    },
    {
      id: 26,
      category: "Lensa",
      image: [id26],
      title: "Lensa Canon EF 70-200mm f/2.8L IS USM",
      include: "Canon Lens EF 70-200 mm f2.8 L II IS USM",
      price: "Rp225,000/day",
    },
    {
      id: 27,
      category: "Lensa",
      image: [id27],
      title: "Lensa Canon EF 85mm f/1.2L USM",
      include: "Canon Lens Fix EF 85mm f/1.2L II USM",
      price: "Rp250,000/day",
    },
    {
      id: 28,
      category: "Lensa",
      image: [id28],
      title: "Lensa Canon EF 100mm f/2.8L Macro IS USM",
      include: "Canon Lens Fix EF 100mm f/2.8 L IS Macro",
      price: "Rp175,000/day",
    },
    {
      id: 29,
      category: "Lensa",
      image: [id29],
      title: "Lensa Canon EF 100-400mm f/4.5-4.6L IS USM",
      include: "Canon EF 100-400mm f/4.5-5.6L IS USM",
      price: "Rp375,000/day",
    },
    {
      id: 30,
      category: "Lensa",
      image: [id30],
      title: "Lensa Canon EF 135mm f/2L USM",
      include: "Canon Lens Fix EF 135 mm f/2L USM",
      price: "Rp175,000/day",
    },
    {
      id: 31,
      category: "Lensa",
      image: [id31],
      title: "Lensa Sony FE 12-24mm f/2.8 GM",
      include: (
        <div>
            E-Mount Lens/Full-Frame Format,
            Aperture Range: f/1.4 to f/16,
            Two XA Elements, Three ED Elements,
            Nano AR and Fluorine Coatings,
            Direct Drive Super Sonic Wave AF Motor,
            AF/MF Switch; Internal Focus,
            Focus Hold Button,
            Physical Aperture Ring; De-Click Switch,
            Dust and Moisture-Resistant Construction,
            Rounded 11-Blade Diaphragm
        </div>
    ),
    
      price: "Rp450,000/day",
    },
    {
      id: 32,
      category: "Lensa",
      image: [id32],
      title: "Lensa Sony FE 14mm f/1.8 GM",
      include: (
        <div>
            Sony FE 14mm f/1.8 GM Lens,
            Front Cap,
            Sony ALC-R1EM Rear Lens Cap,
            Lens Case,
            Filter Template
        </div>
    ),
    
      price: "Rp400,000/day",
    },
    {
      id: 33,
      category: "Lensa",
      image: [id33],
      title: "Lensa Sony FE 16-35 f/2.8 GM",
      include: (
        <div>
            E-Mount Lens/Full-Frame Format,
            Aperture Range: f/2.8 to f/22,
            Two Extra-Low Dispersion Elements,
            Three Aspherical and Two XA Elements,
            Nano AR Coating and Fluorine Coating,
            Direct Drive Super Sonic Wave AF Motors,
            Focus Hold Button; AF/MF Switch,
            Dust and Moisture-Resistant Construction,
            Eleven-Blade Circular Diaphragm
        </div>
    ),
    
      price: "Rp350,000/day",
    },
    {
      id: 34,
      category: "Lensa",
      image: [id34],
      title: "Lensa Sony FE 24 f/1.4 GM",
      include: (
        <div>
            E-Mount Lens/Full-Frame Format,
            Aperture Range: f/1.4 to f/16,
            Two XA Elements, Three ED Elements,
            Nano AR and Fluorine Coatings,
            Direct Drive Super Sonic Wave AF Motor,
            AF/MF Switch; Internal Focus,
            Focus Hold Button,
            Physical Aperture Ring; De-Click Switch,
            Dust and Moisture-Resistant Construction,
            Rounded 11-Blade Diaphragm
        </div>
    ),
    
      price: "Rp350,000/day",
    },
    {
      id: 35,
      category: "Lensa",
      image: [id35],
      title: "Lensa Sony FE 24-70 f/2.8 GM",
      include: (
        <div>
            E-Mount Lens/Full-Frame Format,
            Aperture Range: f/2.8 to f/22,
            One XA Element & Two Aspherical Elements,
            One ED Element & One Super ED Element,
            Nano AR Coating,
            Direct Drive Super Sonic Wave AF Motor,
            Focus Hold Button; Internal Focus,
            AF/MF Switch; Zoom Lock Switch,
            Dust and Moisture-Resistant Construction,
            Nine-Blade Circular Diaphragm
        </div>
    ),
    
      price: "Rp350,000/day",
    },
    {
      id: 36,
      category: "Lensa",
      image: [id36],
      title: "Lensa Sony FE 35mm f/1.4 GM",
      include: (
        <div>
            E-Mount Lens/Full-Frame Format,
            Aperture Range: f/1.4 to f/16,
            Two XA Elements, One ED Element,
            Nano AR II and Fluorine Coatings,
            Dual XD Linear AF Motors, Internal Focus,
            Focus Hold Button, AF/MF Switch,
            Physical Aperture Ring; De-Click Switch,
            Dust and Moisture-Resistant Construction,
            Rounded 11-Blade Diaphragm
        </div>
    ),
    
      price: "Rp350,000/day",
    },
    {
      id: 37,
      category: "Lensa",
      image: [id37],
      title: "Lensa Sony FE 50mm f/1.2 GM",
      include: (
        <div>
            E-Mount Lens/Full-Frame Format,
            Aperture Range: f/1.2 to f/16,
            Three XA Elements,
            Nano AR II and Fluorine Coatings,
            Four XD Linear AF Motors, Internal Focus,
            Focus Hold Button, AF/MF Switch,
            Physical Aperture Ring; De-Click Switch,
            Dust and Moisture-Resistant Construction,
            Filter Diameter: 72mm,
            Rounded 11-Blade Diaphragm
        </div>
    ),
    
      price: "Rp350,000/day",
    },
    {
      id: 38,
      category: "Lensa",
      image: [id38],
      title: "Lensa Sony FE 70-200mm f/2.8 GM",
      include: (
        <div>
            E-Mount Lens/Full-Frame Format,
            Aperture Range: f/2.8 to f/22,
            One XA Element & Two Aspherical Elements,
            Four ED Elements & Two Super ED Elements,
            Nano AR Coating,
            Dual Linear & Super Sonic Wave AF Motors,
            Optical SteadyShot Image Stabilization,
            Focus Hold Buttons; Focus Range Limiter,
            Dust and Moisture-Resistant Construction,
            Eleven-Blade Circular Diaphragm
        </div>
    ),
    
      price: "Rp400,000/day",
    },
    {
      id: 39,
      category: "Lensa",
      image: [id39],
      title: "Lensa Sony FE 85mm f/1.4 GM",
      include: (
        <div>
            E-Mount Lens/Full-Frame Format,
            Aperture Range: f/1.4 to f/22,
            One Extra-Low Dispersion Element,
            Double Linear AF Motor,
            Focus Hold Button; AF/MF Switch,
            Nine-Blade Circular Diaphragm
        </div>
    ),
    
      price: "Rp350,000/day",
    },
    {
      id: 40,
      category: "Lensa",
      image: [id40],
      title: "Lensa Sony FE 100mm f/2.8 STF GM OSS",
      include: (
        <div>
            E-Mount Lens/Full-Frame Format,
            Aperture Range: f/2.8 to 20 | T5.6 to 22,
            Apodization Element for Smooth Bokeh,
            One Aspherical Element & One ED Element,
            Nano AR Coating,
            Direct Drive Super Sonic Wave AF Motor,
            Optical SteadyShot Image Stabilization,
            Physical Aperture Ring; De-Click Switch,
            Macro Switching Ring; Focus Hold Button,
            Eleven-Blade Circular Diaphragm
        </div>
    ),
    
      price: "Rp375,000/day",
    },
    {
      id: 41,
      category: "Lensa",
      image: [id41],
      title: "Lensa Sony FE 100-400mm f/4.5-5.6 STF GM OSS",
      include: (
        <div>
            Sony FE 100-400mm f/4.5-5.6 GM OSS Lens,
            Sony ALC-F77S 77mm Front Lens Cap,
            Sony ALC-R1EM Rear Lens Cap,
            ALC-SH151 Lens Hood,
            Tripod Mount
        </div>
    ),
    
      price: "Rp550,000/day",
    },
    {
      id: 42,
      category: "Lensa",
      image: [id42],
      title: "Lensa Sony FE 135mm f/1.8 GM",
      include: (
        <div>
            ARAK FOKUS MINIMUM = 0,7 m (2,3 ft),
            RASIO PERBESARAN MAKSIMUM (X) = 0,25,
            DIAMETER FILTER (MM) = 82,
            BERAT = 950 g (33,6 oz.)
        </div>
    ),
    
      price: "Rp400,000/day",
    },
    {
      id: 44,
      category: "Audio",
      image: [id44],
      title: "Sennheiser EW 100 G3 Wireless Clip On",
      include: (
        <div>
            Mini Jack to Mini Jack Cable (1x),
            XLR to XLR Cable (1x),
            Hotshoe (1x),
            Mic (1x)
        </div>
    ),
    
      price: "Rp175,000/day"
    },
    {
      id: 45,
      category: "Audio",
      image: [id45],
      title: "Sennheiser EW 112P G4 Wireless Clip On",
      include: (
        <div>
            EK 100 G4 Camera-Mountable Receiver,
            SK 100 G4 Bodypack Transmitter,
            ME 2-II Omnidirectional Clip-On Lav Mic,
            1680 Tunable Frequencies Across 42 MHz,
            Auto Frequency Scan Finds Open Bands,
            3-Level Squelch to Block Interference,
            20 Banks with 12 Channel Presets Each,
            Compander for Clearer Sound,
            Auto-Lock Prevents Accidental Adjustment,
            Not including battery
        </div>
    ),
    
      price: "Rp200,000/day"
    },
    {
      id: 46,
      category: "Audio",
      image: [id46],
      title: "Sennheiser SKP 100 G3 Plug On Transmitter",
      include: "*Not Including Battery",
      price: "Rp200,000/day"
    },
    {
      id: 47,
      category: "Audio",
      image: [id47],
      title: "Sennheiser Boom Mic MKH-416",
      include: (
        <div>
            Mic Sennheiser MKH-416 (1x),
            Pistol Grip (1x),
            Windshield Basket (1x),
            Windscreen Wool (1x),
            Stick Boom/Boom Pole (1x),
            Audio Cable 15 Meter (2x)
        </div>
    ),
    
      price: "Rp250,000/day"
    },
    {
      id: 48,
      category: "Audio",
      image: [id47],
      title: "Sennheiser Boom Mic MKH-60",
      include: (
        <div>
            Mic Sennheiser MKH-60 (1x),
            Pistol Grip (1x),
            Windshield Basket (1x),
            Windscreen Wool (1x),
            Stick Boom/Boom Pole (1x),
            Audio Cable 15 Meter (2x)
        </div>
    ),
    
      price: "Rp300,000/day"
    },
    {
      id: 49,
      category: "Audio",
      image: [id49],
      title: "Saramonic Blink 500 B2 Wireless Clip On",
      include: (
        <div>
            2 Transmitter (TX) + 1 Receiver (RX),
            Lavalier Microphones (2x),
            USB Charger Cable (3x),
            USB Charger Adapter (1x),
            Camera AUXX Cable (1x),
            Smartphone AUX Cable (1x)
        </div>
    ),
    
      price: "Rp175,000/day"
    },
    {
      id: 50,
      category: "Audio",
      image: [id50],
      title: "Saramonic Blink 500 B4 Wireless Clip On",
      include: (
        <div>
            Bodypack Wireless Transmitter with Omni Lavalier Microphone (2.4 GHz) (2x),
            RXUC Dual-Channel Digital Wireless Receiver for USB Type-C Devices (2.4 GHz) (1x),
            RXDi Dual-Channel Digital Wireless Receiver for Lightning iOS Devices (2.4 GHz) (1x),
            SR-M1 Omnidirectional Lavalier Microphones (2x),
            Alligator-Style Lavalier Mic Clips (2x),
            Foam Windscreens (2x),
            1' Gold-Plated USB Type-C to USB Type-A Charging Cables (2x),
            Pairing Pin (1x)
        </div>
    ),
    
      price: "Rp200,000/day"
    },
    {
      id: 51,
      category: "Audio",
      image: [id51],
      title: "Audio Headphone",
      include: "Headphones ",
      price: "Rp100,000/day"
    },
    {
      id: 52,
      category: "Audio",
      image: [id52],
      title: "Sound Devices 552 Portable 5-Channel ",
      include: "Sound Devices 552 Portable 5-Channel Production Mixer",
      price: "Rp350,000/day"
    },
    {
      id: 53,
      category: "Audio",
      image: [id53],
      title: "Audio Recorder Zoom H6N (Basic)",
      include: (
        <div>
            An extremely flexible 6-in/2-out mobile recording system,
            4 x XLR/TRS combination inputs with microphone preamps and phantom power (+12/+24/+48V) provide ample connections,
            Modular input connection lets you attach onboard microphones and additional input jacks,
            Gain knobs for each input make setting levels fast and easy,
            Included X/Y and Mid-Side stereo microphone modules,
            Captures audio at up to 24-bit/96kHz professional-grade resolution,
            Doubles as a handy USB recording interface with your Mac, Windows PC, or iPad (via Apple Camera Connection Kit, not included),
            Large full-color LCD display with virtual VU meters lets you keep an eye on your levels,
            Overdubbing with up to 99 takes provides extreme mobile music production flexibility,
            Built-in processing includes highpass filtering, compression, limiting, MS stereo width decoding,
            Loop Playback with user-defined start and stop points, variable playback speed (from 50% to 150%), and variable pitch per track make this an excellent practice tool,
            Chromatic tuner and metronome built in for added convenience,
            Records onto SD, SDHC, and SDXC storage media with up 128GB capacity,
            Includes 2GB micro SD card with adapter,
            Record for up to 20 hours on just 4 x AA batteries,
            Also includes operation manual, 2GB SD card, 4 x AA batteries, USB cable, foam windscreen, Cubase LE software, carrying case,
            Not including battery
        </div>
    ),
    
      price: "Rp200,000/day"
    },
    {
      id: 54,
      category: "Audio",
      image: [id54],
      title: "Zoom H8 8-Input / 12-Track Portable Handy Recorder",
      include: (
        <div>
            Zoom H8 8-Input / 12-Track Portable Handy Recorder,
            Zoom XYH-6 - X/Y Microphone Capsule for Zoom H5 and H6 Field Recorders,
            4 x AA Batteries,
        </div>
    ),
    
      price: "Rp250,000/day"
    },
    {
      id: 55,
      category: "Audio",
      image: [id55],
      title: "Audio Recorder Zoom F8 Multi Track",
      include: (
        <div>
            8x XLR/TRS Combo Jacks with +75 dB Gain,
            Up to 192 kHz/24-Bit PCM Recording,
            Record Up to 10 Tracks Simultaneously,
            Dual Channel Recordings at Two Levels,
            Works with Zoom Microphone Capsules,
            2x SDXC Card Slots,
            Built-In Slate Microphone,
            AC, External DC Adaptor,
            Supports SMPTE Timecode I/O,
            +24V/+48V Phantom Power,
            Not including battery
        </div>
    ),
    
      price: "Rp350,000/day"
    },
    {
      id: 56,
      category: "Audio",
      image: [id56],
      title: "Rode Videomic Rycote",
      include: "RODE VideoMic Rycote",
      price: "Rp125,000/day"
    },
    {
      id: 57,
      category: "Audio",
      image: [id57],
      title: "Kabel Audio XLR",
      include: "Kabel XLR",
      price: "Rp100,000/day"
    },
    {
      id: 58,
      category: "Audio",
      image: [id58],
      title: "Hollyland Solidcom (8 headset + 1 Basic)",
      include: (
        <div>
            Hollyland Solidcom C1-8S Full-Duplex Wireless DECT Intercom System with 9 Headsets and HUB Base (1.9 GHz),
            8 x Remote Headset,
            HUB Base,
            HUB Headset with 3.5mm Plug,
            8-Slot Charging Base,
            9 x Over-Ear Leather Cushion,
            9 x On-Ear Foam Cushion,
            9 x Mic Windscreen,
            16 x Rechargeable Battery,
            USB Type-A to USB Type-C Cable,
            2 x Power Adapter
        </div>
    ),
    
      price: "Rp1,250,000/day"
    },
    {
      id: 60,
      category: "Lighting",
      image: [id60],
      title: "ARRI M Series M40 With Ballast High Speed",
      include: (
        <div>
            ARRI Head with 4000W Bohlam (1x),
            ARRI Head to Ballast Cable - 50' (15.2m) (1x),
            ARRI 2500/4000 Max HS Ballast with ALF/ DMX/ 120/220V/ 50/60Hz (1x),
            Heavy Stand Light (1x)
        </div>
    ),
      price: "Rp1,500,000/day"
    },
    {
      id: 61,
      category: "Lighting",
      image: [id61],
      title: "ARRI M Series M18 With Ballast High Speed",
      include: (
        <div>
            1 x M18 HMI Lamp Head,
            1 x EB MAX High Speed Ballast,
            1 x Head-to-Ballast Cable,
            1 x Four-Way Barndoor Set,
            High Output Reflector, 16.8" Diameter,
            1200 Hz High-Speed Filming Capability,
            DMX Control, 50 to 100% Dimming,
            Active Line Filter (ALF),
            Compensation of Cable Losses (CCL),
            300 Hz: 270-360 Hz, 1000 Hz: 900-1200 Hz
        </div>
    ),
    
      price: "Rp1,000,000/day"
    },
    {
      id: 62,
      category: "Lighting",
      image: [id62],
      title: "ARRI HMI 4,000 Kw Parlight ",
      include: "ARRI HMI 4000 WATT FF PARLIGHT",
      price: "Rp800,000/day"
    },
    {
      id: 63,
      category: "Lighting",
      image: [id63],
      title: "ARRI HMI 2500 Watt Flicker Free ",
      include: (
        <div>
            Light Stand (1x),
            Electronic Ballast 2500 (1x),
            Sandbag (1x),
            Kabel Overlength 15m (1x)
        </div>
    ),
    
      price: "Rp400,000/day"
    },
    {
      id: 64,
      category: "Lighting",
      image: [id64],
      title: "Aputure Amaran F21C ",
      include: (
        <div>
            Amaran F21c RGBWW LED Mat (V-Mount, 2 x 1'),
            Control Box with V-Mount Battery Plate,
            9.8' Head Extension Cable,
            Support Bracket,
            Right-Angle Baby Pin Adapter,
            Straight Baby Pin Adapter,
            Tilting Stand Mount,
            F21 Softbox Frame,
            F21 Diffusion Cloth 1 (1/2 Grid Cloth),
            F21 Diffusion Cloth 2 (2.5 Stop),
            F21 45 ° Light Control Grid,
            Multi-Voltage AC Power Supply,
            AC Power Cable,
            Carrying Case
        </div>
    ),
    
      price: "Rp350,000/day"
    },
    {
      id: 65,
      category: "Lighting",
      image: [id65],
      title: "Aputure Amaran F22C ",
      include: (
        <div>
            F22c RGBWW LED Mat (V-Mount, 2 x 2'),
            Control Box with V-Mount Battery Plate,
            9.8' Head Extension Cable,
            Support Bracket,
            Right-Angle Baby Pin Adapter,
            Straight Baby Pin Adapter,
            Tilting Stand Mount,
            F22 Softbox Frame,
            F22 Diffusion Cloth 1 (1/2 Grid Cloth),
            F22 Diffusion Cloth 2 (2.5 Stop),
            F22 45 ° Light Control Grid,
            Multi-Voltage AC Power Supply,
            AC Power Cable,
            Carrying Case
        </div>
    ),
    
      price: "Rp400,000/day"
    },
    {
      id: 66,
      category: "Lighting",
      image: [lighting1],
      title: "Aputure Amaran 5 Inch",
      include: "",
      price: "Rp125,000/day"
    },
    {
      id: 67,
      category: "Lighting",
      image: [id67],
      title: "Aputure LS 300D Mark II (Kit)",
      include: (
        <div>
            1 x Light Storm 300d II Light Head,
            1 x Standard Reflector,
            1 x Ballast + Controller Box With V Mount Battery Plates,
            1 x Control Box to Head Cable,
            1 x Aputure Wireless Remote Control,
            1 x AC Power Supply/Ballast to Control Box XLR Cable,
            1 x Lightstand/C-Stand *Optional,
            1 x Carrying Case
        </div>
    ),    
      price: "Rp550,000/day"
    },
    {
      id: 68,
      category: "Lighting",
      image: [id68],
      title: "Aputure LS 600D Pro (Kit)",
      include: (
        <div>
            600W Daylight-Balanced Professional BowensMount Point-Source LED,
            Comparable to 1,200W HMI: 8,500+ lux @ 3m with LS 600 Series Hyper Reflector,
            AC & Battery Power Options: Dual Multi-Voltage Battery Plate Power Supply,
            Battery Charging: Can Charge Gold/V-Mount Batteries When Plugged into AC Power,
            Sidus Link, LumenRadio CRMX, Art-net & sACN via etherCON, 5-Pin DMX512
        </div>
    ),
    
      price: "Rp600,000/day"
    },
    {
      id: 69,
      category: "Lighting",
      image: [id69],
      title: "Aputure LS 1200D (kit)",
      include: (
        <div>
            5600K Color Temperature, 95 CRI/TLCI,
            15, 30, and 45° Reflectors Included,
            Wireless DMX, Bluetooth Control,
            4 Dimming Modes, Comparable to 1600W HMI
        </div>
    ),
    
      price: "Rp850,000/day"
    },
    {
      id: 70,
      category: "Lighting",
      image: [id70],
      title: "Aputure NOVA P600C RGB",
      include: (
        <div>
            Aputure Nova P600c RGBWW LED Panel with Hard-Shell Case Kit,
            Control Box,
            Neutrik powerCON AC Power Cable (19.7'),
            5-Pin XLR Head Cable (9.8'),
            Lightning Clamp,
            Wheeled Hard-Shell Carry Case
        </div>
    ),
    
      price: "Rp850,000/day"
    },
    {
      id: 71,
      category: "Lighting",
      image: [id71],
      title: "Aputure Spotlight 19\"",
      include: "Lastolite Strobo Gobo Set of 3 Architectural Gobos",
      price: "Rp200,000/day"
    },
    {
      id: 72,
      category: "Lighting",
      image: [id72],
      title: "Aputure Fresnel",
      include: (
        <div>
            65cm / 26in Omni-Directional Soft Box,
            2-Second Quick Set Up Design,
            270º Beam Angle,
            Included 1-Stop Diffusion,
            Included Fabric Light Control Skirt
        </div>
    ),
    
      price: "Rp100,000/day"
    },
    {
      id: 73,
      category: "Lighting",
      image: [id73],
      title: "Aputure Latern",
      include: (
        <div>
            Carbon slider video rail 120 cm,
            Smooth, fluid movement of the camera,
            Height adjustable, can also be mounted on a tripod,
            120 cm usable rail part
        </div>
    ),
    
      price: "Rp150,000/day"
    },
    {
      id: 74,
      category: "Lighting",
      image: [id74],
      title: "Godox TL120 SET",
      include: (
        <div>
            4 x Godox TL120 RGB Tube Light,
            Power Cord,
            Adapter,
            RJ45 Cable,
            2 x Clip,
            2 x Steel Cable,
            Carry Case,
            Godox 2.4 GHz Remote Control for LC500R LED Light Stick,
            Godox CB47 Carry Bag
        </div>
    ),    
      price: "Rp700,000/day"
    },
    {
      id: 75,
      category: "Lighting",
      image: [id75],
      title: "Godox TL60 SET",
      include: (
        <div>
            4 x Godox TL60 Tube Light,
            4 x Power Adapters,
            4 x DC Cables,
            4 x Retaining Clips,
            4 x Toggle Clips,
            4 x Fixed Bases,
            1 x Remote Control,
            4 x RJ-45 Cables,
            8 x Wire Ropes,
            1 x Carrying Bag
        </div>
    ),    
      price: "Rp500,000/day"
    },
    {
      id: 76,
      category: "Lighting",
      image: [id76],
      title: "Godox FL100 Flexible LED 40x60 cm",
      include: (
        <div>
            Godox FL100 Flexible LED Light (15.8 x 23.6") (1x),
            Flexible Light (1x),
            Controller (1x),
            Adapter (1x),
            Foldable X-Mount Holder (1x),
            Light Stand Bracket (1x),
            RC-A5 Remote Control (1x),
            Hook and Loop Fastener (1x),
            Softbox FL-SF 4060 with Grid (1x),
            Light Stand/Statip (1x)
        </div>
    ),    
      price: "Rp275,000/day"
    },
    {
      id: 77,
      category: "Lighting",
      image: [id77],
      title: "Godox FL150 Flexible LED 30x120 cm",
      include: (
        <div>
            1x Flexible LED Light 30x120cm,
            1x Controller,
            1x Adapter,
            1x Power Cord,
            1x Foldable X-Mount Holder,
            1x Light Stand Bracket,
            1x RC-A5 Remote Control,
            1x Hook & Loop Fastener,
            1x Light Stand/Statip,
            1x Softbox Kit fl-sf30120 with Honeycomb Grid
        </div>
    ),
    
      price: "Rp275,000/day"
    },
    {
      id: 78,
      category: "Lighting",
      image: [lighting1],
      title: "LED Light Panel 1x1 include (Charger, Battery & Tripod)",
      include: "",
      price: "Rp250,000/day"
    },
    {
      id: 80,
      category: "Light Accessories",
      image: [id80],
      title: "Frames (1 x1 m , 1x2m)",
      include: "Frame ukuran 1x1m, 1x2m",
      price: "Rp50,000/day"
    },
    {
      id: 81,
      category: "Light Accessories",
      image: [id81],
      title: "Cutterlight Set",
      include: "Black Flag Kit / Cutterlight / Light Control Flag",
      price: "Rp100,000/day"
    },
    {
      id: 82,
      category: "Light Accessories",
      image: [id82],
      title: "Overlenght",
      include: "Extension Cable / Overlength Cable / Kabel Perleng (10m)",
      price: "Rp25,000/day"
    },
    {
      id: 83,
      category: "Light Accessories",
      image: [lighting1],
      title: "Panel Box",
      include: "",
      price: "Rp25,000/day"
    },
    {
      id: 84,
      category: "Light Accessories",
      image: [id84],
      title: "Floppy",
      include: "Floppy Cutter 4x4 feet (Floppy Flag)",
      price: "Rp125,000/day"
    },
    {
      id: 85,
      category: "Light Accessories",
      image: [id85],
      title: "Tracing Frame + Filter",
      include: (
        <div>
            Ukuran 4x4 Kaki (120cm x 120cm),
            1 Set = 4 Pcs
        </div>
    ),
    
      price: "Rp125,000/day"
    },
    {
      id: 86,
      category: "Light Accessories",
      image: [id86],
      title: "Reflector",
      include: (
        <div>
            Dapat digunakan sebagai diffuser atau reflektor.
            Desain lipat membuka dan menutup dengan cepat.
        </div>
    ),
      price: "Rp30,000/day"
    },
    {
      id: 87,
      category: "Light Accessories",
      image: [id87],
      title: "Sandbag",
      include: "Sandbag / Shotbag",
      price: "Rp10,000/day"
    },
    {
      id: 89,
      category: "Equipment List",
      image: [id89],
      title: "DJI Phantom 4 (No Pilot)",
      include: "DJI Phantom 4 Body Full set - DJI Phantom 4 Body Upper Middle",
      price: "Rp1,200,000/day"
    },
    {
      id: 90,
      category: "Equipment List",
      image: [id90],
      title: "DJI Mavic 2 Pro (No Pilot)",
      include: (
        <div>
            1x Remote Controller,
            3x Baterai Penerbangan Cerdas,
            1x Pengisi daya,
            1x Micro SD 64gb Extreme,
            1x Kabel Daya,
            6x Baling-baling
        </div>
    ),
      price: "Rp2,000,000/day"
    },
    {
      id: 91,
      category: "Equipment List",
      image: [id91],
      title: "DJI Mavic 3 Classic (No Pilot)",
      include: (
        <div>
            DJI Mavic 3 Classic dengan DJI RC Remote,
            Baterai Penerbangan Cerdas,
            Penutup Penyimpanan,
            2 x Baling-baling Low-Noise,
            Pengisi daya portabel 65W,
            Kabel USB-C,
            RC Remote Controller,
            Tambahan Stik Kontrol RC Pro
        </div>
    ),
      price: "Rp2,500,000/day"
    },
    {
      id: 92,
      category: "Equipment List",
      image: [id92],
      title: "DJI Mavic 3 Cine Premium (no Pilot)",
      include: (
        <div>
            DJI Mavic 3 Cine Premium Combo,
            DJI RC Pro Remote Controller,
            3 x Baterai Penerbangan Cerdas,
            Set Filter ND #1 (ND4, ND8, ND16 & ND32),
            Set Filter ND #2 (ND64, ND128, ND256 & ND512),
            6 x Pasang Baling-baling Low-Noise,
            Pengisi daya portabel 65W,
            Hub Pengisian Baterai,
            Penutup Penyimpanan,
            Stik Kontrol DJI untuk RC Pro Remote Controller,
            Kabel Data Lightspeed (10 Gb/s),
            Kabel USB Type-C,
            Tas Bawaan yang Dapat Dikonversi
        </div>
    ),
    
      price: "Rp5,000,000/day"
    },
    {
      id: 93,
      category: "Equipment List",
      image: [id93],
      title: "Dji Ronin RS2 Pro Combo",
      include: (
        <div>
            DJI RS 2 Gimbal Stabilizer,
            DJI Ronin-S Extended Grip/Tripod,
            BG30 Grip,
            Kabel kontrol multi-kamera DJI R (USB Type-C),
            Kabel kontrol multi-kamera DJI R (Sony Multi),
            Kabel kontrol multi-kamera DJI R (Micro-USB),
            Kabel kontrol multi-kamera DJI R (Mini-USB),
            Pegangan koper DJI R,
            Plate cepat lepas DJI R (Bagian bawah),
            Plate cepat lepas DJI R (Bagian atas),
            Tali pengikat lensa DJI R
        </div>
    ),
    
      price: "Rp650,000/day"
    },
    {
      id: 94,
      category: "Equipment List",
      image: [id94],
      title: "Dji Ronin RS3 Pro Combo",
      include: "The DJI RS3 Pro Gimbal Stabilizer Combo has been redesigned to be sleeker and more powerful than the Ronin RS 2 gimbal, with its carbon fiber body weighing only 3.3 lb and a payload of up to 10 lb. This compact gimbal is tough enough to support and stabilize midsize cameras such as the Sony FX6 or Canon C70 with a 24-70mm zoom lens attached. With a larger full-color OLED display, a new fine-tuning knob, and greater stability, the RS 3 Pro can become an essential part of your professional camera kit. The Combo kit includes additional essentials such as an updated focus motor, a motor rod mount kit with gear strip, a briefcase handle, a BG30 grip, a lower extended quick release plate, a wireless Ronin Image Transmitter, a phone holder, USB multicamera control cables, multiple HDMI cables, power cables, and a lens-fastening strap.",
      price: "Rp750,000/day"
    },
    {
      id: 95,
      category: "Equipment List",
      image: [id95],
      title: "Zhiyun Crane 3-Lab",
      include: (
        <div>
            Kapasitas muatan: 4.5kg (10lb),
            Poros: 3-axis (pitch, roll, dan yaw),
            Rentang rotasi: 360°,
            Pegangan kedua yang dapat digerakkan,
            Transmisi gambar WiFi HDMI 1080p,
            Monitor dan kontrol jarak jauh smartphone,
            Opsi kontrol motor Fokus & Zoom,
            Kontrol timelapse & motion lapse,
            Mode auto-follow objek,
            Baterai: 4x 18650,
            Masa pakai baterai: Hingga 18 jam
        </div>
    ),    
      price: "Rp350,000/day"
    },
    {
      id: 96,
      category: "Equipment List",
      image: [id96],
      title: "Portha Jib (1.5 meter)",
      include: "Portha Jib 1.5 Meter (Egripment)",
      price: "Rp750,000/day"
    },
    {
      id: 97,
      category: "Equipment List",
      image: [id97],
      title: "40\" C-Stand",
      include: (
        <div>
            2.5" Grip Head and 40" Arm,
            Konstruksi dilapisi krom,
            3 Bagian, 2 Riser,
            Base yang dapat dilepas,
            Bagian utama 40"
        </div>
    ),
      price: "Rp100,000/day"
    },
    {
      id: 98,
      category: "Equipment List",
      image: [id98],
      title: "20\" C-Stand",
      include: "C-Stand Mini 20\" Kupo Master + Arm (Turtle Base)",
      price: "Rp50,000/day"
    },
    {
      id: 99,
      category: "Equipment List",
      image: [id99],
      title: "Magic Arm ",
      include: "Super Clamp & Magic Arm",
      price: "Rp40,000/day"
    },
    {
      id: 100,
      category: "Equipment List",
      image: [id100],
      title: "Alligator Clamp",
      include: "Alligator Clamp",
      price: "Rp20,000/day"
    },
    {
      id: 101,
      category: "Equipment List",
      image: [id101],
      title: "Butterfly Set 8x8 (Black Cloth, Silk, Ultra Bounce) & 2pcs C-Stand)",
      include: (
        <div>
            Frame 8x8 Feet (1 Pcs),
            Kain Silk 8x8 Feet (1 Pcs),
            Kain Black Net 8x8 Feet (1 Pcs),
            Kain Black Solid 8x8 Feet (1 Pcs),
            Kain Green Screen 8x8 Feet (1 Pcs),
            C-Stand (2 Pcs)
        </div>
    ),
    
      price: "Rp50,000/day"
    },
    {
      id: 102,
      category: "Equipment List",
      image: [id101],
      title: "Butterfly Set 6x6 (Black Cloth, Silk, Ultra Bounce, Net) & 2pcs C-Stand",
      include: (
        <div>
            Frame 6x6 Feet (4 Pcs),
            Kain Silk 6x6 Feet (4 Pcs),
            Kain Black Net 6x6 Feet (1 Pcs),
            Kain Black Solid 6x6 Feet (1 Pcs),
            Kain Green Screen 6x6 Feet (1 Pcs),
            C-Stand (2 Pcs)
        </div>
    ),
    
      price: "Rp450,000/day"
    },
    {
      id: 103,
      category: "Equipment List",
      image: [id103],
      title: "Slider 120Cm",
      include: (
        <div>
            Carbon slider video rail 120 cm,
            Smooth, fluid movement of the camera,
            Height adjustable, can also be mounted on a tripod,
            120 cm usable rail part
        </div>
    ),
    
      price: "Rp200,000/day"
    },
    {
      id: 104,
      category: "Equipment List",
      image: [id104],
      title: "Applebox (Set Of 5)",
      include: "1 Set (Isi 5)",
      price: "Rp100,000/day"
    },
    {
      id: 105,
      category: "Equipment List",
      image: [id105],
      title: "Tripod",
      include: "Tripod E-Image Bowl 50mm (GH03)",
      price: "Rp100,000/day"
    },
    {
      id: 106,
      category: "Equipment List",
      image: [id106],
      title: "Monopod",
      include: (
        <div>
            Monopod Manfrotto 682B,
            Ball Head 496RC2
        </div>
    ),    
      price: "Rp75,000/day"
    },
    {
      id: 107,
      category: "Equipment List",
      image: [id107],
      title: "Statif/Light Stand",
      include: "Is black aluminium lighting stand that designed to support larger light banks and heads up to a maximum of 9.5kg. The height can be adjusted between 102 and 264cm and is also available as a set of two complete with carry bag.",
      price: "Rp20,000/day"
    },
    {
      id: 108,
      category: "Equipment List",
      image: [id108],
      title: "V-Mount Battery",
      include: (
        <div>
            Li-ion battery, no memory effect,
            Kapasitas besar 95Wh, 14.4V,
            D-tap output socket,
            Indikator daya LED 4 level,
            Metal V-mount plate,
            Mount wireless receiver,
            Aman untuk transportasi udara
        </div>
    ),
      price: "Rp100,000/day"
    },
    {
      id: 109,
      category: "Equipment List",
      image: [id109],
      title: "Charger V-Mount",
      include: "V-Mount Charger 2 Slot",
      price: "Rp50,000/day"
    },
    {
      id: 111,
      category: "Equipment List",
      image: [id111],
      title: "Monitor Director CINEGEARS Ruige 21.5\" With Case",
      include: (
        <div>
            HDMI,
            3G-SDI,
            HD-SDI,
            SD-SDI
        </div>
    ),
    
      price: "Rp350,000/day"
    },
    {
      id: 112,
      category: "Equipment List",
      image: [id112],
      title: "Monitor Director Bestview SP17 17\" 4K Multi View",
      include: (
        <div>
            - Hardcase (1x)
            - Monitor Sunhood (1x)
            - DC Power (1x)
        </div>
    ),
      price: "Rp350,000/day"
    },
    {
      id: 113,
      category: "Equipment List",
      image: [id113],
      title: "FeelWorld F5 5.0\" Monitor (4K Support and Tilt Arm)",
      include: (
        <div>
            Battery NP-F770 (3x),
            Charger (1x),
            Kabel Micro/Mini/Full HDMI (2x),
            Tilt Arm (1x),
            Sun Hood (1x)
        </div>
    ),
    
      price: "Rp200,000/day"
    },
    {
      id: 114,
      category: "Equipment List",
      image: [id114],
      title: "Atomos Ninja V 5\" 4K HDMI Recording Monitor (Include SSD)",
      include: (
        <div>
            Battery NP-F770 (2x),
            Charger Battery (1x),
            AC Adaptor (1x),
            Mini Ballhead/Articulating Arm (1x),
            Kabel-kabel (HDMI/Mini HDMI/Micro HDMI),
            SSD Angelbird 480gb (2x),
            SSD Reader (1x)
        </div>
    ),
    
      price: "Rp400,000/day"
    },
    {
      id: 115,
      category: "Equipment List",
      image: [id115],
      title: "Atomos Shogun Inferno 7\" 4K HDMI/Quad 3G-SDI/12G-SDI + SSD",
      include: (
        <div>
            NP-F770 Battery (6x),
            Sandisk SSD 480 Gb Extreme Pro (2x),
            SSD Card Reader (1x),
            Battery Charger Single (1x),
            Battery Charger Double (1x),
            Magic Arm 7.8" (1x),
            AC Adaptor (1x),
            Monitor Hood (1 set),
            Audio Cable (1x),
            HDMI Cable (Additional)
        </div>
    ),
    
      price: "Rp500,000/day"
    },
    {
      id: 116,
      category: "Equipment List",
      image: [id116],
      title: "Monitor Feelworld Master 7” Ultra Bright",
      include: "Featuring a 323 PPI (pixels-per-inch) IPS LCD panel, the FeelWorld 7\" 4K Ultra-Bright Monitor with Loop-Through HDMI and 3G-SDI is up to the task of displaying SD, HD, UHD, and DCI 4K video. The LCD panel boast a 1920 x 1200 resolution, 16:10 aspect ratio, 1200:1 contrast ratio, and a 2200 cd/m² measured brightness for easy viewing outdoors as well as in. The IPS LCD provides good off-axis viewing, allowing you to see the image without being directly in front of the monitor and not experiencing distorted colors or contrast. The monitors chassis is both thin and light at an inch thick and weighing only 13.9 oz with the removable L-series battery plate.",
      price: "Rp250,000/day"
    },
    {
      id: 117,
      category: "Equipment List",
      image: [id117],
      title: "Hollyland Mars 4K Wireless Video Transmission System",
      include: (
        <div>
            Hollyland Mars 4K Wireless Video Transmission System,
            Mars 4K Transmitter,
            Mars 4K Receiver,
            4 x Antenna
        </div>
    ),
    
      price: "Rp500,000/day"
    },
    {
      id: 118,
      category: "Equipment List",
      image: [id118],
      title: "Hollyland Cosmo C1 SDI/HDMI Wireless Video Transmission System",
      include: (
        <div>
            Cosmo C1 Transmitter,
            Cosmo C1 Receiver,
            4 x Antenna,
            2 x Mushroom Antenna,
            Shoe Mount,
            Pan/Tilt Mount with Shoe Mount,
            USB Type-C Adapter,
            1 x DC Adapter,
            1 x D-Tap to DC Cable,
            6 x Battery NP-F770,
            1 x Charger NP-F Series,
            Kabel HDMI 1m/SDI 1m (Sesuai Kebutuhan)
        </div>
    ),
    
      price: "Rp600,000/day"
    },
    {
      id: 119,
      category: "Equipment List",
      image: [id119],
      title: "Hollyland Mars 400S PRO SDI/HDMI Wireless Video Transmission System",
      include: (
        <div>
            Hollyland Mars 400S PRO SDI/HDMI Wireless Video Transmission System,
            Mars 400S PRO Transmitter,
            Mars 400S PRO Receiver,
            5 x Antenna,
            DC Power Adapter
        </div>
    ),
    
      price: "Rp400,000/day"
    },
    {
      id: 121,
      category: "Equipment List",
      image: [id121],
      title: "Battery Canon LP-E6",
      include: "Battery/batre canon LP-E6 for EOS 5D ,7D, 60D",
      price: "Rp45,000/day"
    },
    {
      id: 122,
      category: "Equipment List",
      image: [id122],
      title: "Battery Sony NP-FZ100 (For Sony A9, A7 III, A7R III series)",
      include: (
        <div>
            7.2 V Output,
            2280 mAh Capacity,
            16.4 Wh
        </div>
    ),
    
      price: "Rp45,000/day"
    },
    {
      id: 123,
      category: "Equipment List",
      image: [id123],
      title: "Battery Sony NP-FW50",
      include: "Fits: Sony Alpha NEX-3, 5, 6 and 7 Series Cameras, DSLR-SLT-A33, DSLR-SLT-A55",
      price: "Rp35,000/day"
    },
    {
      id: 124,
      category: "Equipment List",
      image: [id124],
      title: "Battery Sony NP-F770",
      include: "Battery Sony NP-F770",
      price: "Rp50,000/day"
    },
    {
      id: 125,
      category: "Equipment List",
      image: [id125],
      title: "Charger Canon LC-E6 for LP-E6",
      include: "Charger Canon LC-E6",
      price: "Rp45,000/day"
    },
    {
      id: 126,
      category: "Equipment List",
      image: [id126],
      title: "Charger Sony for NP-FZ100",
      include: "Daya: AC, 100-240 V, 50 Hz / 60 Hz",
      price: "Rp45,000/day"
    },
    {
      id: 127,
      category: "Equipment List",
      image: [id127],
      title: "Charger Sony for NP-FW50",
      include: "InfoLITHIUM NP-FW50",
      price: "Rp45,000/day"
    },
    {
      id: 128,
      category: "Equipment List",
      image: [id128],
      title: "Sandisk SDXC EXTREME 64gb",
      include: "SanDisk Extreme SDXC UHS-I 64GB ",
      price: "Rp45,000/day"
    },
    {
      id: 129,
      category: "Equipment List",
      image: [id129],
      title: "Sandisk MicroSD Extreme 64GB",
      include: "SanDisk Extreme MicroSD 64GB",
      price: "Rp45,000/day"
    },
    {
      id: 130,
      category: "Equipment List",
      image: [id130],
      title: "CFexpress Sony 80GB Type A TOUGH",
      include: "CFexpress Sony 80GB Type A TOUGH ",
      price: "Rp85,000/day"
    },
    {
      id: 131,
      category: "Equipment List",
      image: [id131],
      title: "Sony MRW-G2 CFexpress Type A/SD Memory Card Reader",
      include: (
        <div>
            Sony MRW-G2 CFexpress Type A/SD Memory Card Reader,
            USB Type-C to Type-C Cable,
            USB Type-C to Type-A Cable
        </div>
    ),
    
      price: "Rp125,000/day"
    },
    {
      id: 132,
      category: "Equipment List",
      image: [id132],
      title: "Cable BNC (100 Meter)",
      include: "Kabel BNC 100 Meter",
      price: "Rp250,000/day"
    },
    {
      id: 133,
      category: "Equipment List",
      image: [id132],
      title: "Cable BNC (50 Meter)",
      include: "Kabel BNC 50 Meter",
      price: "Rp150,000/day"
    },
    {
      id: 134,
      category: "Equipment List",
      image: [id134],
      title: "Cable XLR (50 Meter)",
      include: "Kabel XLR 50 Meter",
      price: "Rp100,000/day"
    },
    {
      id: 135,
      category: "Equipment List",
      image: [id135],
      title: "Cable HDMI (10 Meter)",
      include: "Kabel HDMI (10 meter)",
      price: "Rp80,000/day"
    },
    {
      id: 136,
      category: "Equipment List",
      image: [id135],
      title: "Cable HDMI (5 Meter)",
      include: "Kabel HDMI (5 meter)",
      price: "Rp50,000/day"
    },
    {
      id: 137,
      category: "Equipment List",
      image: [id137],
      title: "ND Filter Variable",
      include: "Filter Variabel Ring",
      price: "Rp75,000/day"
    },
    {
      id: 138,
      category: "Equipment List",
      image: [id138],
      title: "Step Up Ring",
      include: "Step Up Ring (82mm to 52mm)",
      price: "Rp50,000/day"
    },
    {
      id: 139,
      category: "Equipment List",
      image: [id139],
      title: "Mobil Grandmax / Carry + BBM (Jakarta)",
      include: (
        <div>
            Warna Putih,
            Tahun Produksi 2012 akhir,
            Transmisi: Manual,
            BBM: Premium,
            Plat: B (Jakarta Selatan),
            Silinder: 1300 cc,
            Audio: MP3 DVD/USB/Radio
        </div>
    ),
      price: "Rp500,000/day"
    },
    {
      id: 140,
      category: "Equipment List",
      image: [id139],
      title: "Mobil Grandmax / Carry Non BBM (Jakarta)",
      include: (
        <div>
            Warna Putih,
            Tahun Produksi 2012 akhir,
            Transmisi: Manual,
            BBM: Premium,
            Plat: B (Jakarta Selatan),
            Silinder: 1300 cc,
            Audio: MP3 DVD/USB/Radio
        </div>
    ),
      price: "Rp400,000/day"
    },
    {
      id: 141,
      category: "Equipment List",
      image: [id139],
      title: "Mobil Grandmax / Carry + BBM (Bogor/Puncak)",
      include: (
        <div>
            Warna Putih,
            Tahun Produksi 2012 akhir,
            Transmisi: Manual,
            BBM: Premium,
            Plat: B (Jakarta Selatan),
            Silinder: 1300 cc,
            Audio: MP3 DVD/USB/Radio
        </div>
    ),
      price: "Rp600,000/day"
    },
    {
      id: 142,
      category: "Equipment List",
      image: [id139],
      title: "Mobil Grandmax / Carry + BBM (Luar Kota)",
      include: (
                <div>
                    Warna Putih,
                    Tahun Produksi 2012 akhir,
                    Transmisi: Manual,
                    BBM: Premium,
                    Plat: B (Jakarta Selatan),
                    Silinder: 1300 cc,
                    Audio: MP3 DVD/USB/Radio
                </div>
            ),
      price: "Rp-/day"
    },
    {
      id: 143,
      category: "Equipment List",
      image: [lighting1],
      title: "Crew Pengawal Alat",
      include: "",
      price: "Rp350,000/day"
    }
  ];

  useEffect(() => {
    const urlSearchKeyword = location.pathname.replace("/search/", "");

    setSearchKeyword(urlSearchKeyword);
  }, [location.pathname]);

  const filteredProducts = products.filter(
    (product) =>
      product.title.toLowerCase().includes(searchKeyword.toLowerCase()) ||
      product.category.toLowerCase().includes(searchKeyword.toLowerCase())
  );

  const addToCart = (product) => {
    if (!cart.some((item) => item.id === product.id)) {
      setCart([...cart, product]);
      toast.success(
        <div>
          <span style={{ color: "#15b3bd" }}>{product.title}</span>{" "}
          <span style={{ color: "#0c1439" }}>added to cart!</span>
        </div>,
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          bodyClassName: "toast-body-red",
        }
      );
    }
  };

  return (
    <div>
      <Intro />
      <Navigation />
      <br />
      <Container>
        <h2>Searching for "{searchKeyword}"</h2>
        <br />
        <Row>
          {filteredProducts.map((product) => (
            <Col md={3} className="productWrapper" key={product.id}>
              <Card className="productImage">
                <Link
                  to={`/product/${product.id}`}
                  style={{ textDecoration: "none" }}
                >
                  <Image
                    src={product.image}
                    alt={product.category}
                    className="images"
                  />
                </Link>

                <div className="p-2 m-1 text-dark">
                  <Card.Title
                    className="text-left"
                    style={{ color: "#919191" }}
                  >
                    {product.category}
                  </Card.Title>
                  <Link
                    to={`/product/${product.id}`}
                    style={{ textDecoration: "none" }}
                  >
                    <Card.Text
                      className="text-left productTitle"
                      style={{
                        color: "#15B3BD",
                        fontSize: "1rem",
                        fontWeight: "bold",
                      }}
                    >
                      {product.title}
                    </Card.Text>
                  </Link>
                  <br />
                  <Card.Text className="text-left productInclude">
                    {product.include}
                  </Card.Text>
                  <Card.Text className="text-left">{product.price}</Card.Text>
                  <button
                    type="button"
                    className="btn-add-to-cart"
                    onClick={() => addToCart(product)}
                  >
                    ADD TO CART
                  </button>
                </div>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default Searchpage;
