import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { useState } from "react";
import { Link } from "react-router-dom";

const Navigation = () => {
  const [camDropdownOpen, setCamDropdownOpen] = useState(false);
  const [lensDropdownOpen, setLensDropdownOpen] = useState(false);
  const [lightDropdownOpen, setLightDropdownOpen] = useState(false);
  const [audioDropdownOpen, setAudioDropdownOpen] = useState(false);
  const [equipmentDropdownOpen, setEquipmentDropdownOpen] = useState(false);

  const handleCamDropdownToggle = () => {
    setCamDropdownOpen(!camDropdownOpen);
  };

  const handleLensDropdownToggle = () => {
    setLensDropdownOpen(!lensDropdownOpen);
  };

  const handleLightDropdownToggle = () => {
    setLightDropdownOpen(!lightDropdownOpen);
  };

  const handleAudioDropdownToggle = () => {
    setAudioDropdownOpen(!audioDropdownOpen);
  };

  const handleEquipmentDropdownToggle = () => {
    setEquipmentDropdownOpen(!equipmentDropdownOpen);
  };

  return (
    <Navbar className="custom-navbar" expand="lg">
      <div className="container">
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto" style={{ display: "flex", width: "100%" }}>
            <NavDropdown
              title="Camera"
              id="cam-dropdown"
              show={camDropdownOpen}
              onMouseEnter={handleCamDropdownToggle}
              onMouseLeave={handleCamDropdownToggle}
              style={{ flex: 1, textAlign: "center", position: "relative" }}
            >
              <Link to="/category/Canon" className="dropdown-item">
                Canon
              </Link>
              <Link to="/category/Sony" className="dropdown-item">
                Sony
              </Link>
              <Link to="/category/GoPro" className="dropdown-item">
                GoPro
              </Link>
              <Link to="/category/Red Komodo" className="dropdown-item">
                Red Komodo
              </Link>
            </NavDropdown>
            <NavDropdown
              title="Lensa"
              id="lens-dropdown"
              show={lensDropdownOpen}
              onMouseEnter={handleLensDropdownToggle}
              onMouseLeave={handleLensDropdownToggle}
              style={{ flex: 1, textAlign: "center", position: "relative" }}
            >
              <Link to="/category/Canon" className="dropdown-item">
                Canon
              </Link>
              <Link to="/category/Sony" className="dropdown-item">
                Sony
              </Link>
              <Link to="/category/Compact Prime" className="dropdown-item">
                Compact Prime
              </Link>
            </NavDropdown>
            <NavDropdown
              title="Audio"
              id="audio-dropdown"
              show={audioDropdownOpen}
              onMouseEnter={handleAudioDropdownToggle}
              onMouseLeave={handleAudioDropdownToggle}
              style={{ flex: 1, textAlign: "center", position: "relative" }}
            >
              <Link to="/category/Sennheiser" className="dropdown-item">
              Sennheiser
              </Link>
              <Link to="/category/Saramonic" className="dropdown-item">
                Saramonic
              </Link>
              <Link to="/category/Sound Devices" className="dropdown-item">
                Sound Devices
              </Link>
            </NavDropdown>
            <NavDropdown
              title="Lighting"
              id="light-dropdown"
              show={lightDropdownOpen}
              onMouseEnter={handleLightDropdownToggle}
              onMouseLeave={handleLightDropdownToggle}
              style={{ flex: 1, textAlign: "center", position: "relative" }}
            >
              <Link to="/category/ARRI" className="dropdown-item">
                ARRI
              </Link>
              <Link to="/category/Aputure" className="dropdown-item">
                Aputure
              </Link>
              <Link to="/category/Godox" className="dropdown-item">
                Godox
              </Link>
              <Link to="/category/Light Accessories" className="dropdown-item">
                Light Accessories
              </Link>
            </NavDropdown>
            <NavDropdown
              title="Equipment List"
              id="equipment-dropdown"
              show={equipmentDropdownOpen}
              onMouseEnter={handleEquipmentDropdownToggle}
              onMouseLeave={handleEquipmentDropdownToggle}
              style={{ flex: 1, textAlign: "center", position: "relative" }}
            >
              <Link to="/category/Grip" className="dropdown-item">
                Grip
              </Link>
              <Link to="/category/Monitor" className="dropdown-item">
                Monitor
              </Link>
              <Link to="/category/Additional Item" className="dropdown-item">
                Additional Item
              </Link>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
};

export default Navigation;
