import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams, useNavigate } from "react-router-dom";
import Navigation from "../components/Navigation";
import Intro from "../components/Intro";
import Footer from "../components/Footer";
import { Row, Col, Image } from "react-bootstrap";
import kamera1 from "../assets/kamera1.png";
import lighting1 from "../assets/lighting1.png";
import id1 from "../assets/Camera_Canon_DSLR_5D_MarkIII.png";
import id1_1 from "../assets/Camera_Canon_DSLR_5D_MarkIII2.png";
import id1_2 from "../assets/Camera_Canon_DSLR_5D_MarkIII_Battery.png";
import id1_3 from "../assets/Camera_Canon_DSLR_5D_MarkIII_Charger.png";
import id1_4 from "../assets/Camera_Canon_DSLR_5D_MarkIII_Memory.png";
import id2 from "../assets/Camera_Canon_DSLR_5D_MarkIV.png";
import id2_1 from "../assets/Camera_Canon_DSLR_5D_MarkIV2.png";
import id2_2 from "../assets/Camera_Canon_DSLR_5D_MarkIV3.png";
import id2_3 from "../assets/Camera_Canon_DSLR_5D_MarkIV_Battery.png";
import id2_4 from "../assets/Camera_Canon_DSLR_5D_MarkIV_Memory.png";
import id3 from "../assets/Camera_Sony_A7S_MarkII.png";
import id4 from "../assets/Camera_Sony_A7S_MarkIII.png";
import id5 from "../assets/Camera_Sony_FX3.png";
import id6 from "../assets/Camera_Sony_FX30.png";
import id7 from "../assets/Camera_Sony_PXW_FX6.png";
import id8 from "../assets/Camera_Red_Komodo.png";
import id9 from "../assets/Camera_GoPro.png";
import id12 from "../assets/Camera_Sony_A7_MarkIII.png";
import id12_1 from "../assets/Camera_Sony_A7_MarkIII2.png";
import id12_2 from "../assets/Camera_Sony_A7_MarkIII3.png";
import id12_3 from "../assets/Camera_Sony_A7_MarkIII_Battery.png";
import id13 from "../assets/Lensa_Compact_Prime_2.png";
import id14 from "../assets/Lensa_Canon_Cinemalens.png";
import id15 from "../assets/Lensa_Canon_SumirePrime.png";
import id16 from "../assets/Lensa_Canon_EF_8-15mm.png";
import id18 from "../assets/Lensa_Canon_EF_14mm_f2.8L.png";
import id19 from "../assets/Lensa_Canon_EF_16-35mm_f2.8L.png";
import id21 from "../assets/Lensa_Canon_EF_24mm_f1.4L_USM.png";
import id22 from "../assets/Lensa_Canon_EF_24-70mm_f2.8L.png";
import id23 from "../assets/Lensa_Canon_EF_24-105mm_f4L.png";
import id24 from "../assets/Lensa_Canon_EF_35mm_f1.4L.png";
import id25 from "../assets/Lensa_Canon_EF_50mm_f1.2L.png";
import id26 from "../assets/Lensa_Canon_EF_70-200mm_f2.8L.png";
import id27 from "../assets/Lensa_Canon_EF_85mm_f1.2L.png";
import id28 from "../assets/Lensa_Canon_EF_100mm_f2.8L.png";
import id29 from "../assets/Lensa_Canon_EF_100-400mm_f4.5-4.6L.png";
import id30 from "../assets/Lensa_Canon_EF_135mm_f2L.png";
import id31 from "../assets/Lensa_Sony_FE_12-24mm_f2.8.png";
import id32 from "../assets/Lensa_Sony_FE_14mm_f1.8.png";
import id33 from "../assets/Lensa_Sony_FE_16-35_f2.8.png";
import id34 from "../assets/Lensa_Sony_FE_24_f1.4.png";
import id35 from "../assets/Lensa_Sony_FE_24-70_f1.4.png";
import id36 from "../assets/Lensa_Sony_FE_35mm_f1.4.png";
import id37 from "../assets/Lensa_Sony_FE_50mm_f1.2.png";
import id38 from "../assets/Lensa_Sony_FE_70-200mm_f2.8.png";
import id39 from "../assets/Lensa_Sony_FE_85mm_f1.4.png";
import id40 from "../assets/Lensa_Sony_FE_100mm_f2.8_STF_GM_OSS.png";
import id41 from "../assets/Lensa_Sony_FE_100-400mm_f4.5-5.6_STF_GM_OS.png";
import id42 from "../assets/Lensa_Sony_135mm_f1.8.png";
import id44 from "../assets/Audio_Sennheiser_EW_100_G3_Wireless_Clip_On.png";
import id45 from "../assets/Audio_Sennheiser_EW_112P_G4_Wireless_Clip_On.png";
import id46 from "../assets/Audio_Sennheiser_SKP_100_G3_Plug_On_Transmitter.png";
import id47 from "../assets/Audio_Sennheiser_Boom_Mic_MKH-416.png";
import id49 from "../assets/Audio_Saramonic_Blink_500_B2_Wireless_Clip_On.png";
import id49_1 from "../assets/Audio_Saramonic_Blink_500_B2_Wireless_Clip_On2.png";
import id50 from "../assets/Audio_Saramonic_Blink_500_B4_Wireless_Clip_On.png";
import id50_1 from "../assets/Audio_Saramonic_Blink_500_B4_Wireless_Clip_On2.png";
import id51 from "../assets/Audio_Audio_Headphone.png";
import id52 from "../assets/Audio_Sound_Devices_552_Portable_5-Channel.png";
import id53 from "../assets/Audio_Recorder_Zoom_H6N__Basic.png";
import id53_1 from "../assets/Audio_Recorder_Zoom_H6N__Basic_2.png";
import id54 from "../assets/Audio_Zoom_H8_8-Input_12-Track_Portable_Handy_Recorder.png";
import id54_1 from "../assets/Audio_Zoom_H8_8-Input_12-Track_Portable_Handy_Recorder2.png";
import id55 from "../assets/Audio_Recorder_Zoom_F8_Multi_Track.png";
import id55_1 from "../assets/Audio_Recorder_Zoom_F8_Multi_Track2.png";
import id55_2 from "../assets/Audio_Recorder_Zoom_F8_Multi_Track3.png";
import id55_3 from "../assets/Audio_Recorder_Zoom_F8_Multi_Track4.png";
import id55_4 from "../assets/Audio_Recorder_Zoom_F8_Multi_Track5.png";
import id56 from "../assets/Audio_Rode_Videomic_Rycote.png";
import id57 from "../assets/Audio_Kabel_audio_XLR.png";
import id58 from "../assets/Audio_Hollyland_Solidcom__8_headset_+_1_Basic.png";
import id60 from "../assets/Lighting_ARRI_M_Series_M40_With_Ballast_High_Speed.png";
import id60_1 from "../assets/Lighting_ARRI_M_Series_M40_With_Ballast_High_Speed2.png";
import id61 from "../assets/Lighting_ARRI_M_Series_M18_With_Ballast_High_Speed.png";
import id61_1 from "../assets/Lighting_ARRI_M_Series_M18_With_Ballast_High_Speed2.png";
import id61_2 from "../assets/Lighting_ARRI_M_Series_M40_With_Ballast_High_Speed.png";
import id61_3 from "../assets/Lighting_ARRI_M_Series_M40_With_Ballast_High_Speed2.png";
import id62 from "../assets/Lighting_ARRI_HMI_4000KW.png";
import id63 from "../assets/Lighting_ARRI_HMI_2500_Watt_Flicker_Free.png";
import id64 from "../assets/Lighting_Aputure_Amaran_F21C.png";
import id64_1 from "../assets/Lighting_Aputure_Amaran_F21C2.png";
import id64_2 from "../assets/Lighting_Aputure_Amaran_F21C3.png";
import id64_3 from "../assets/Lighting_Aputure_Amaran_F21C4.png";
import id64_4 from "../assets/Lighting_Aputure_Amaran_F21C5.png";
import id64_5 from "../assets/Lighting_Aputure_Amaran_F21C6.png";
import id64_6 from "../assets/Lighting_Aputure_Amaran_F21C7.png";
import id65 from "../assets/Lighting_Aputure_Amaran_F22C.png";
import id65_1 from "../assets/Lighting_Aputure_Amaran_F22C2.png";
import id65_2 from "../assets/Lighting_Aputure_Amaran_F22C3.png";
import id65_3 from "../assets/Lighting_Aputure_Amaran_F22C4.png";
import id65_4 from "../assets/Lighting_Aputure_Amaran_F22C5.png";
import id67 from "../assets/Lighting_Aputure_LS_300D_Mark_II__Kit.png";
import id67_1 from "../assets/Lighting_Aputure_LS_300D_Mark_II__Kit_2.png";
import id67_2 from "../assets/Lighting_Aputure_LS_300D_Mark_II__Kit_3.png";
import id67_3 from "../assets/Lighting_Aputure_LS_300D_Mark_II__Kit_4.png";
import id67_4 from "../assets/Lighting_Aputure_LS_300D_Mark_II__Kit_5.png";
import id68 from "../assets/Lighting_Aputure_LS_600D_Pro_(Kit).png";
import id69 from "../assets/Lighting_Aputure_LS_1200D.png";
import id70 from "../assets/Lighting_Aputure_NOVA_P600C_RGB.png";
import id70_1 from "../assets/Lighting_Aputure_NOVA_P600C_RGB2.png";
import id70_2 from "../assets/Lighting_Aputure_NOVA_P600C_RGB3.png";
import id71 from "../assets/Lighting_Aputure_Spotlight_19.png";
import id72 from "../assets/Lighting_Aputure_Fresnel.png";
import id73 from "../assets/Lighting_Aputure_Lantern.png";
import id73_1 from "../assets/Lighting_Aputure_Lantern2.png";
import id74 from "../assets/Lighting_Godox_TL120_SET.png";
import id75 from "../assets/Lighting_Godox_TL60_SET.png";
import id76 from "../assets/Lighting_Godox_FL100_Flexible_LED_40x60_cm.png";
import id77 from "../assets/Lighting_Godox_FL150_Flexible_LED_30x120_cm.png";
import id80 from "../assets/Light_Acc_Frames.png";
import id81 from "../assets/Light_Acc_Cutterlight_Set.png";
import id82 from "../assets/Light_Acc_Overlength.png";
import id84 from "../assets/Light_Acc_Floppy.png";
import id85 from "../assets/Light_Acc_Tracing_Frame_+_Filter.png";
import id86 from "../assets/Light_Acc_Reflector.png";
import id87 from "../assets/Light_Acc_Sandbag.png";
import id89 from "../assets/Eq_Grip_Dji_Phantom_4.png";
import id89_1 from "../assets/Eq_Grip_Dji_Phantom_41.png";
import id90 from "../assets/Eq_Grip_DJI_Mavic_2_Pro__No_Pilot.png";
import id90_1 from "../assets/Eq_Grip_DJI_Mavic_2_Pro__No_Pilot_2.png";
import id91 from "../assets/Eq_Grip_DJI_Mavic_3_Classic__No_Pilot.png";
import id91_1 from "../assets/Eq_Grip_DJI_Mavic_3_Classic__No_Pilot_2.png";
import id92 from "../assets/Eq_Grip_DJI_Mavic_3_Cine_Premium__no_Pilot.png";
import id92_1 from "../assets/Eq_Grip_DJI_Mavic_3_Cine_Premium__no_Pilot_2.png";
import id93 from "../assets/Eq_Grip_Dji_Ronin_RS2_Pro_Combo.png";
import id93_1 from "../assets/Eq_Grip_Dji_Ronin_RS2_Pro_Combo2.png";
import id94 from "../assets/Eq_Grip_Dji_Ronin_RS3_Pro_Combo.png";
import id94_1 from "../assets/Eq_Grip_Dji_Ronin_RS3_Pro_Combo2.png";
import id95 from "../assets/Eq_Grip_Zhiyun_Crane_3-Lab.png";
import id96 from "../assets/Eq_Grip_Portha_Jib__1.5_meter.png";
import id97 from "../assets/Eq_Grip_40_C-Stand.png";
import id98 from "../assets/Eq_Grip_20_C-Stand.png";
import id99 from "../assets/Eq_Grip_Magic_Arm.png";
import id100 from "../assets/Eq_Grip_Alligator_Clamp.png";
import id101 from "../assets/Eq_Grip_Butterfly_Set_6x6__Black_Cloth__Silk__Ultra_Bounce__Net____2pcs_C.png";
import id103 from "../assets/Eq_Grip_Slider_120Cm.png";
import id104 from "../assets/Eq_Grip_Applebox__Set_Of_5.png";
import id105 from "../assets/Eq_Grip_Tripod.png";
import id106 from "../assets/Eq_Grip_Monopod.png";
import id107 from "../assets/Eq_Grip_Statif_Light_Stand.png";
import id108 from "../assets/Eq_Grip_V-Mount_Battery.png";
import id109 from "../assets/Eq_Grip_Charger_V-Mount.png";
import id111 from "../assets/Eq_Monitor_Monitor_Director_CINEGEARS_Ruige_21.5_With_Case.png";
import id111_1 from "../assets/Eq_Monitor_Monitor_Director_CINEGEARS_Ruige_21.5_With_Case2.png";
import id112 from "../assets/Eq_Monitor_Monitor_Director_Bestview_SP17_17_4K_Multi_View.png";
import id112_1 from "../assets/Eq_Monitor_Monitor_Director_Bestview_SP17_17_4K_Multi_View2.png";
import id113 from "../assets/Eq_Monitor_FeelWorld_F5_5.0_Monitor__4K_Support_and_Tilt_Arm.png";
import id113_1 from "../assets/Eq_Monitor_FeelWorld_F5_5.0_Monitor__4K_Support_and_Tilt_Arm_2.png";
import id114 from "../assets/Eq_Monitor_Atomos_Ninja_V_5_4K_HDMI_Recording_Monitor__Include_SSD.png";
import id114_1 from "../assets/Eq_Monitor_Atomos_Ninja_V_5_4K_HDMI_Recording_Monitor__Include_SSD_2.png";
import id115 from "../assets/Eq_Monitor_Atomos_Shogun_Inferno_7_4K_HDMI_Quad_3G-SDI_12G-SDI_+_SSD.png";
import id115_1 from "../assets/Eq_Monitor_Atomos_Shogun_Inferno_7_4K_HDMI_Quad_3G-SDI_12G-SDI_+_SSD2.png";
import id115_2 from "../assets/Eq_Monitor_Atomos_Shogun_Inferno_7_4K_HDMI_Quad_3G-SDI_12G-SDI_+_SSD3.png";
import id115_3 from "../assets/Eq_Monitor_Atomos_Shogun_Inferno_7_4K_HDMI_Quad_3G-SDI_12G-SDI_+_SSD4.png";
import id116 from "../assets/Eq_Monitor_Monitor_Feelworld_Master_7_Ultra_Bright.png";
import id116_1 from "../assets/Eq_Monitor_Monitor_Feelworld_Master_7_Ultra_Bright2.png";
import id117 from "../assets/Eq_Monitor_Hollyland_Mars_4K_Wireless_Video_Transmission_System.png";
import id117_1 from "../assets/Eq_Monitor_Hollyland_Mars_4K_Wireless_Video_Transmission_System2.png";
import id117_2 from "../assets/Eq_Monitor_Hollyland_Mars_4K_Wireless_Video_Transmission_System3.png";
import id117_3 from "../assets/Eq_Monitor_Hollyland_Mars_4K_Wireless_Video_Transmission_System4.png";
import id118 from "../assets/Eq_Monitor_Hollyland_Cosmo_C1_SDI_HDMI_Wireless_Video_Transmission_System.png";
import id118_1 from "../assets/Eq_Monitor_Hollyland_Cosmo_C1_SDI_HDMI_Wireless_Video_Transmission_System2.png";
import id119 from "../assets/Eq_Monitor_Hollyland_Mars_400S_PRO_SDI_HDMI_Wireless_Video_Transmission_System.png";
import id121 from "../assets/Eq_Add_Battery_Canon_LP-E6.png";
import id122 from "../assets/Eq_Add_Battery_Sony_NP-FZ100__For_Sony_A9__A7_III__A7R_III_series.png";
import id122_1 from "../assets/Eq_Add_Battery_Sony_NP-FZ100__For_Sony_A9__A7_III__A7R_III_series_2.png";
import id123 from "../assets/Eq_Add_Battery_Sony_NP-FW50.png";
import id124 from "../assets/Eq_Add_Battery_Sony_NP-F770.png";
import id125 from "../assets/Eq_Add_Charger_Canon_LC-E6_for_LP-E6.png";
import id126 from "../assets/Eq_Add_Charger_Sony_for_NP-FZ100.png";
import id127 from "../assets/Eq_Add_Charger_Sony_for_NP-FW50.png";
import id128 from "../assets/Eq_Add_Sandisk_MicroSD_Extreme_64gb.png";
import id129 from "../assets/Eq_Add_Sandisk_SDXC_EXTREME_64gb.png";
import id130 from "../assets/Eq_Add_CFexpress_Sony_80GB_Type_A_TOUGH.png";
import id131 from "../assets/Eq_Add_Sony_MRW-G2_CFexpress_Type_A_SD_Memory_Card_Reader.png";
import id132 from "../assets/Eq_Add_Cable_BNC__100_Meter.png";
import id134 from "../assets/Eq_Add_Cable_XLR__50_Meter.png";
import id135 from "../assets/Eq_Add_Cable_HDMI__10_Meter.png";
import id137 from "../assets/Eq_Add_ND_Filter.png";
import id138 from "../assets/Eq_Add_Step_Up_Ring.png";
import id139 from "../assets/Eq_Add_Mobil_Grandmax__Carry_+_BBM__Jakarta.png";

const ProductDetailspage = () => {
    const { id } = useParams();
    const [cart, setCart] = useState([]);
    const [selectedImage, setSelectedImage] = useState("");
    const [showFullDescription, setShowFullDescription] = useState(false);
    const navigate = useNavigate();

    const addToCart = (product) => {
        if (!cart.some((item) => item.id === product.id)) {
            setCart([...cart, product]);
            toast.success(
                <div>
                    <span style={{ color: "#15b3bd" }}>{product.title}</span>{" "}
                    <span style={{ color: "#0c1439" }}>added to cart!</span>
                </div>,
                {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    bodyClassName: "toast-body-red",
                    onClick: () => navigate("/cart"),
                }
            );
        }
    };

    useEffect(() => {
        const savedCart = JSON.parse(localStorage.getItem("cart")) || [];
        setCart(savedCart);
    }, []);

    useEffect(() => {
        localStorage.setItem("cart", JSON.stringify(cart));
    }, [cart]);

    const products = [
        {
            id: 1,
            category: "Camera",
            image: [id1, id1_1, id1_2, id1_3, id1_4],
            title: "Canon DSLR 5D Mark III (Body Only)",
            description:
            (
                <div>
                    - 22.3MP Full-Frame CMOS Sensor <br />
                    - DIGIC 5+ Image Processor <br />
                    - 3.2" 1.04m-Dot ClearView II LCD Monitor <br />
                    - Full HD 1080p Video Recording at 30 fps <br />
                    - 61-Point High Density Reticular AF <br />
                    - Native ISO 25600, Extended to ISO 102400 <br />
                    - 6 fps Shooting in RAW+JPEG <br />
                    - 63-Zone Dual Layer Metering Sensor <br />
                    - 14-Bit RAW Files and S-RAW Format <br />
                    - Magnesium Alloy Body, SD/CF Card Slots
                </div>
            ),
            price: "Rp350,000/day",
        },
        {
            id: 2,
            category: "Camera",
            image: [id2, id2_1, id2_2, id2_3, id2_4],
            title: "Canon DSLR 5D Mark IV (Body Only)",
            description:
            (
                <div>
                    - 30.4MP Full-Frame CMOS Sensor <br />
                    - DIGIC 6+ Image Processor <br />
                    - 3.2" 1.62m-Dot Touchscreen LCD Monitor <br />
                    - DCI 4K Video at 30 fps; 8.8MP Still Grab <br />
                    - 61-Point High Density Reticular AF <br />
                    - Native ISO 32000, Expanded to ISO 102400 <br />
                    - Dual Pixel RAW; AF Area Select Button <br />
                    - Dual Pixel CMOS AF and Movie Servo AF <br />
                    - 7 fps Shooting; CF & SD Card Slots <br />
                    - Built-In GPS and Wi-Fi with NFC
                </div>
            ),
            price: "Rp500,000/day",
        },
        {
            id: 12,
            category: "Camera",
            image: [id12, id12_1, id12_2, id12_3],
            title: "Sony A7 Mark III (Body Only)",
            description:
            (
                <div>
                    - 24MP Full-Frame Exmor R BSI CMOS Sensor <br />
                    - BIONZ X Image Processor & Front-End LSI <br />
                    - 693-Point Hybrid AF System <br />
                    - UHD 4K30p Video with HLG & S-Log3 Gammas <br />
                    - 2.36m-Dot Tru-Finder OLED EVF <br />
                    - 3.0" 922k-Dot Tilting Touchscreen LCD <br />
                    - 5-Axis SteadyShot INSIDE Stabilization <br />
                    - ISO 204800 and 10 fps Shooting <br />
                    - Built-In Wi-Fi and NFC, Dual SD Slots <br />
                    - USB Type-C Port, Weather-Sealed Design
                </div>
            ),
            price: "Rp450,000/day",
        },
        {
            id: 3,
            category: "Camera",
            image: [id3],
            title: "Sony A7S Mark II (Body Only)",
            description:
            (
                <div>
                    - Battery Pack (4x) <br />
                    - Sony BC-TRW Battery Charger (1x) <br />
                    - Memory Extreme Pro 64GB (2x) <br />
                    - Tali Strap (1x)
                </div>
            ),
            price: "Rp400,000/day",
        },
        {
            id: 4,
            category: "Camera",
            image: [id4],
            title: "Sony A7S Mark III (Body Only)",
            description:
            (
                <div>
                    - 12MP Full-Frame Exmor R BSI CMOS Sensor <br />
                    - UHD 4K 120p Video, 10-Bit 4:2:2 Internal <br />
                    - 16-Bit Raw Output, HLG & S-Log3 Gammas <br />
                    - 759-Point Fast Hybrid AF <br />
                    - 9.44m-Dot QXGA OLED EVF <br />
                    - 3.0" 1.44m-Dot Vari-Angle Touchscreen <br />
                    - 5-Axis SteadyShot Image Stabilization <br />
                    - Extended ISO 40-409600, 10 fps Shooting <br />
                    - Dual CFexpress Type A/SD Card Slots
                </div>
            ),
            
            price: "Rp750,000/day",
        },
        {
            id: 5,
            category: "Camera",
            image: [id5],
            title: "Sony FX3 (Body Only)",
            description:
            (
                <div>
                    - 12.1MP Full-Frame CMOS Exmor R Sensor <br />
                    - UHD 4K up to 120 | 1080p up to 240 <br />
                    - Compact Form for Cage-Free Operation <br />
                    - 10-Bit 4:2:2 XAVC S-I, 16-Bit Raw Output <br />
                    - S-Cinetone/S-Log3/HLG, 15+ Stops DR <br />
                    - Detachable 2 x XLR/TRS Adapter Handle <br />
                    - Phase Detection AF/Face Tracking/Eye AF <br />
                    - 80 to 409,600 Expanded ISO Range <br />
                    - Dual CFexpress Type A/SDXC Card Slots
                </div>
            ),
            price: "Rp850,000/day",
        },
        {
            id: 6,
            category: "Camera",
            image: [id6],
            title: "Sony FX30 (Body Only)",
            description:
            (
                <div>
                    - 26.1MP APS-C BSI CMOS Sensor <br />
                    - UHD 4K up to 120p <br />
                    - Compact Form Designed for Cage-Free Use <br />
                    - 10-Bit 4:2:2 XAVC S-I, 16-Bit Raw Output <br />
                    - S-Cinetone/S-Log3/HLG, 14+ Stops DR <br />
                    - Phase Detection AF/Face Tracking/Eye AF <br />
                    - Standard ISO 100-32000 | Dual Base ISO <br />
                    - Dual CFexpress Type A/SDXC Card Slots <br />
                    - User LUTs & Timecode Sync Support <br />
                    - Detachable XLR Handle Unit with 2 Inputs
                </div>
            ),
            price: "Rp650,000/day",
        },
        {
            id: 7,
            category: "Camera",
            image: [id7],
            title: "Sony PXW-FX6 (Body Only)",
            description:
            (
                <div>
                    - 4K Full-Frame 10.2MP CMOS Exmor R Sensor <br />
                    - DCI 4K60p | UHD 4K120 | 1080p240 <br />
                    - 15+ Stops of Dynamic Range in S-Log 3 EI <br />
                    - Compact Form Weighs Just less than 2 lb <br />
                    - Phase Detection AF/Face Tracking/Eye AF <br />
                    - Base 800-12,800 ISO / 320-409,600 Max <br />
                    - S-Cinetone, S-Log3, HLG Modes <br />
                    - 10-Bit 4:2:2 XAVC-I/16-Bit Raw Output <br />
                    - Dual CFexpress Type A/SDXC Card Slots
                </div>
            ),
            price: "Rp1,250,000/day",
        },
        {
            id: 8,
            category: "Camera",
            image: [id8],
            title: "RED KOMODO (Non Lensa)",
            description:
            (
                <div>
                    - Lensa Adapter EF/PL ke RF 1x <br />
                    - Body Cage 1x <br />
                    - Baterai BP-955 4x <br />
                    - Charger 1x <br />
                    - CFast 128GB 4x <br />
                    - Pembaca CFast 1x <br />
                    - Monitor SmallHD Indie 7” <br />
                    - Tripod Miller Air Solo 75mm
                </div>
            ),
            price: "Rp4,000,000/day",
        },
        {
            id: 9,
            category: "Camera",
            image: [id9],
            title: "GoPro Hero 9 Black",
            description:
            (
                <div>
                    - Mikrofon Direksional Terintegrasi <br />
                    - Port 3.5mm untuk Mikrofon Eksternal <br />
                    - Port Micro-HDMI untuk Pemutaran <br />
                    - Port USB Type-C, 2 x Cold Shoe Mounts <br />
                    - Desain Melingkar yang Elegan <br />
                    - Kompatibel dengan Mod Display & Light Terpisah
                </div>
            ),
            price: "Rp325,000/day",
        },
        {
            id: 10,
            category: "Camera",
            image: [id9],
            title: "GoPro Hero 10 Black",
            description:
            (
                <div>
                    - Mikrofon Direksional Terintegrasi <br />
                    - Port 3.5mm untuk Mikrofon Eksternal <br />
                    - Port Micro-HDMI untuk Pemutaran <br />
                    - Port USB Type-C, 2 x Cold Shoe Mounts <br />
                    - Desain Melingkar yang Elegan <br />
                    - Kompatibel dengan Mod Display & Light Terpisah
                </div>
            ),
            
            
            price: "Rp350,000/day",
        },
        {
            id: 11,
            category: "Camera",
            image: [id9],
            title: "GoPro Hero 11 Black",
            description:
            (
                <div>
                    - Mikrofon Direksional Terintegrasi <br />
                    - Port 3.5mm untuk Mikrofon Eksternal <br />
                    - Port Micro-HDMI untuk Pemutaran <br />
                    - Port USB Type-C, 2 x Cold Shoe Mounts <br />
                    - Desain Melingkar yang Elegan <br />
                    - Kompatibel dengan Mod Display & Light Terpisah
                </div>
            ),
            price: "Rp375,000/day",
        },
        {
            id: 13,
            category: "Lensa",
            image: [id13],
            title: "Lensa Compact Prime 2 (18, 25, 35, 50, 85) PL/EF",
            description:
            (
                <div>
                    - 18mm T3.6 <br />
                    - 25mm T2.9 <br />
                    - 35mm T1.5 Super Speed <br />
                    - 50mm T1.5 Super Speed <br />
                    - 85mm T1.5 Super Speed
                </div>
            ),
            
            price: "Rp1,550,000/day",
        },
        {
            id: 14,
            category: "Lensa",
            image: [id14],
            title: "Lensa Canon Cinemalens (14,24,35,50,85,135)",
            description:
            (
                <div>
                    - Menutupi Sensor Full Frame & Super 35 <br />
                    - Aperture Maksimum T1.5 <br />
                    - Iris Sebelas Bilah <br />
                    - Penafasan Minim hampir Nol <br />
                    - Dual Focus Markings <br />
                    - Rotasi Fokus 300° Tanpa Langkah <br />
                    - Posisi Gear yang Sama dengan Model CN-E Lainnya <br />
                    - Housing Tahan Debu dan Air
                </div>
            ),
            
            price: "Rp1,550,000/day",
        },
        {
            id: 15,
            category: "Lensa",
            image: [id15],
            title: "Lensa Canon Sumire Prime (14,24,35,50,85,135) PL",
            description:
            (
                <div>
                    - 6 Lensa Canon Sumire <br />
                    - CN-E14mm T3.1 FP X <br />
                    - CN-E20mm T1.5 FP X <br />
                    - CN-E35mm T1.5 FP X <br />
                    - CN-E50mm T1.3 FP X <br />
                    - CN-E85mm T1.3 FP X <br />
                    - CN-E135mm T2.2 FP X
                </div>
            ),
            
            price: "Rp2,800,000/day",
        },
        {
            id: 16,
            category: "Lensa",
            image: [id16],
            title: "Lensa Canon EF 8-15mm f/4L USM (Fish Eye)",
            description:
                "Canon Lens EF 8-15 mm f/4L USM (Fish Eye)",
            price: "Rp300,000/day",
        },
        {
            id: 17,
            category: "Lensa",
            image: [kamera1],
            title: "Lensa Canon EF 11-24mm f/4L USM",
            description:
                "",
            price: "Rp475,000/day",
        },
        {
            id: 18,
            category: "Lensa",
            image: [id18],
            title: "Lensa Canon EF 14mm f/2.8L USM",
            description:
                "Canon Lens EF 14mm f2.8 L USM",
            price: "Rp300,000/day",
        },
        {
            id: 19,
            category: "Lensa",
            image: [id19],
            title: "Lensa Canon EF 16-35mm f/2.8L USM",
            description:
                "Canon Lens EF 16-35 mm f/2.8 L II USM",
            price: "Rp175,000/day",
        },
        {
            id: 20,
            category: "Lensa",
            image: [kamera1],
            title: "Lensa Canon EF 17-40mm f/4L USM",
            description:
                "",
            price: "Rp175,000/day",
        },
        {
            id: 21,
            category: "Lensa",
            image: [id21],
            title: "Lensa Canon EF 24mm f/1.4L USM",
            description:
                "The professional’s wide-angle lens with L-series optics, with a fast f/1.4 aperture, ideal for low light.",
            price: "Rp300,000/day",
        },
        {
            id: 22,
            category: "Lensa",
            image: [id22],
            title: "Lensa Canon EF 24-70mm f/2.8L USM",
            description:
                "Canon Lens EF 24-70mm f/2.8 L USM",
            price: "Rp175,000/day",
        },
        {
            id: 23,
            category: "Lensa",
            image: [id23],
            title: "Lensa Canon EF 24-105mm f/4L USM",
            description:
                "Canon Lens EF 24-105 mm f/4L USM",
            price: "Rp150,000/day",
        },
        {
            id: 24,
            category: "Lensa",
            image: [id24],
            title: "Lensa Canon EF 35mm f/1.4L USM",
            description:
                "Canon Lens Fix EF 35 mm f/1.4L USM",
            price: "Rp300,000/day",
        },
        {
            id: 25,
            category: "Lensa",
            image: [id25],
            title: "Lensa Canon EF 50mm f/1.2L USM",
            description:
                "Canon Lens Fix EF 50 mm f/1.2L USM",
            price: "Rp225,000/day",
        },
        {
            id: 26,
            category: "Lensa",
            image: [id26],
            title: "Lensa Canon EF 70-200mm f/2.8L IS USM",
            description:
                "Canon Lens EF 70-200 mm f2.8 L II IS USM",
            price: "Rp225,000/day",
        },
        {
            id: 27,
            category: "Lensa",
            image: [id27],
            title: "Lensa Canon EF 85mm f/1.2L USM",
            description:
                "Canon Lens Fix EF 85mm f/1.2L II USM",
            price: "Rp250,000/day",
        },
        {
            id: 28,
            category: "Lensa",
            image: [id28],
            title: "Lensa Canon EF 100mm f/2.8L Macro IS USM",
            description:
                "Canon Lens Fix EF 100mm f/2.8 L IS Macro",
            price: "Rp175,000/day",
        },
        {
            id: 29,
            category: "Lensa",
            image: [id29],
            title: "Lensa Canon EF 100-400mm f/4.5-4.6L IS USM",
            description:
                "Canon EF 100-400mm f/4.5-5.6L IS USM",
            price: "Rp375,000/day",
        },
        {
            id: 30,
            category: "Lensa",
            image: [id30],
            title: "Lensa Canon EF 135mm f/2L USM",
            description:
                "Canon Lens Fix EF 135 mm f/2L USM",
            price: "Rp175,000/day",
        },
        {
            id: 31,
            category: "Lensa",
            image: [id31],
            title: "Lensa Sony FE 12-24mm f/2.8 GM",
            description:
            (
                <div>
                    - E-Mount Lens/Full-Frame Format <br />
                    - Rentang Aperture: f/1.4 hingga f/16 <br />
                    - Dua XA Elements, Tiga ED Elements <br />
                    - Nano AR dan Fluorine Coatings <br />
                    - Direct Drive Super Sonic Wave AF Motor <br />
                    - AF/MF Switch, Internal Focus <br />
                    - Tombol Focus Hold <br />
                    - Physical Aperture Ring, De-Click Switch <br />
                    - Konstruksi Tahan Debu dan Tahan Air <br />
                    - Diafragma Lingkaran Sebelas Bilah
                </div>
            ),
            
            price: "Rp450,000/day",
        },
        {
            id: 32,
            category: "Lensa",
            image: [id32],
            title: "Lensa Sony FE 14mm f/1.8 GM",
            description:
            (
                <div>
                    - Sony FE 14mm f/1.8 GM Lens <br />
                    - Tutup Lensa Depan <br />
                    - Tutup Lensa Belakang Sony ALC-R1EM <br />
                    - Kotak Lensa <br />
                    - Template Filter
                </div>
            ),
            
            price: "Rp400,000/day",
        },
        {
            id: 33,
            category: "Lensa",
            image: [id33],
            title: "Lensa Sony FE 16-35 f/2.8 GM",
            description:
            (
                <div>
                    - E-Mount Lens/Full-Frame Format <br />
                    - Rentang Aperture: f/2.8 hingga f/22 <br />
                    - Dua Extra-Low Dispersion Elements <br />
                    - Tiga Aspherical dan Dua XA Elements <br />
                    - Nano AR Coating dan Fluorine Coating <br />
                    - Direct Drive Super Sonic Wave AF Motors <br />
                    - Tombol Focus Hold, AF/MF Switch <br />
                    - Konstruksi Tahan Debu dan Tahan Air <br />
                    - Diafragma Lingkaran Sebelas Bilah
                </div>
            ),
            
            price: "Rp350,000/day",
        },
        {
            id: 34,
            category: "Lensa",
            image: [id34],
            title: "Lensa Sony FE 24 f/1.4 GM",
            description:
            (
                <div>
                    - E-Mount Lens/Full-Frame Format <br />
                    - Rentang Aperture: f/1.4 hingga f/16 <br />
                    - Dua XA Elements, Tiga ED Elements <br />
                    - Nano AR dan Fluorine Coatings <br />
                    - Direct Drive Super Sonic Wave AF Motor <br />
                    - AF/MF Switch, Internal Focus <br />
                    - Tombol Focus Hold <br />
                    - Physical Aperture Ring, De-Click Switch <br />
                    - Konstruksi Tahan Debu dan Tahan Air <br />
                    - Diafragma Lingkaran Sebelas Bilah
                </div>
            ),
            
            price: "Rp350,000/day",
        },
        {
            id: 35,
            category: "Lensa",
            image: [id35],
            title: "Lensa Sony FE 24-70 f/2.8 GM",
            description:
            (
                <div>
                    - E-Mount Lens/Full-Frame Format <br />
                    - Rentang Aperture: f/2.8 hingga f/22 <br />
                    - Satu XA Element & Dua Aspherical Elements <br />
                    - Satu ED Element & Satu Super ED Element <br />
                    - Nano AR Coating <br />
                    - Direct Drive Super Sonic Wave AF Motor <br />
                    - Tombol Focus Hold, Internal Focus <br />
                    - AF/MF Switch, Zoom Lock Switch <br />
                    - Konstruksi Tahan Debu dan Tahan Air <br />
                    - Diafragma Lingkaran Sembilan Bilah
                </div>
            ),
            
            price: "Rp350,000/day",
        },
        {
            id: 36,
            category: "Lensa",
            image: [id36],
            title: "Lensa Sony FE 35mm f/1.4 GM",
            description:
            (
                <div>
                    - E-Mount Lens/Full-Frame Format <br />
                    - Rentang Aperture: f/1.4 hingga f/16 <br />
                    - Dua XA Elements, Satu ED Element <br />
                    - Nano AR II dan Fluorine Coatings <br />
                    - Dual XD Linear AF Motors, Internal Focus <br />
                    - Tombol Focus Hold, AF/MF Switch <br />
                    - Physical Aperture Ring, De-Click Switch <br />
                    - Konstruksi Tahan Debu dan Tahan Air <br />
                    - Diafragma Lingkaran Sebelas Bilah
                </div>
            ),
            
            price: "Rp350,000/day",
        },
        {
            id: 37,
            category: "Lensa",
            image: [id37],
            title: "Lensa Sony FE 50mm f/1.2 GM",
            description:
            (
                <div>
                    - E-Mount Lens/Full-Frame Format <br />
                    - Rentang Aperture: f/1.2 hingga f/16 <br />
                    - Tiga XA Elements <br />
                    - Nano AR II dan Fluorine Coatings <br />
                    - Empat XD Linear AF Motors, Internal Focus <br />
                    - Tombol Focus Hold, AF/MF Switch <br />
                    - Physical Aperture Ring, De-Click Switch <br />
                    - Konstruksi Tahan Debu dan Tahan Air <br />
                    - Diameter Filter: 72mm <br />
                    - Diafragma Lingkaran Sebelas Bilah
                </div>
            ),
            
            price: "Rp350,000/day",
        },
        {
            id: 38,
            category: "Lensa",
            image: [id38],
            title: "Lensa Sony FE 70-200mm f/2.8 GM",
            description:
            (
                <div>
                    - E-Mount Lens/Full-Frame Format <br />
                    - Rentang Aperture: f/2.8 hingga f/22 <br />
                    - Satu XA Element & Dua Aspherical Elements <br />
                    - Empat ED Elements & Dua Super ED Elements <br />
                    - Nano AR Coating <br />
                    - Dual Linear & Super Sonic Wave AF Motors <br />
                    - Optical SteadyShot Image Stabilization <br />
                    - Tombol Focus Hold; Focus Range Limiter <br />
                    - Konstruksi Tahan Debu dan Tahan Air <br />
                    - Diafragma Lingkaran Sebelas Bilah
                </div>
            ),
            
            price: "Rp400,000/day",
        },
        {
            id: 39,
            category: "Lensa",
            image: [id39],
            title: "Lensa Sony FE 85mm f/1.4 GM",
            description:
            (
                <div>
                    - E-Mount Lens/Full-Frame Format <br />
                    - Rentang Aperture: f/1.4 hingga f/22 <br />
                    - Satu Extra-Low Dispersion Element <br />
                    - Double Linear AF Motor <br />
                    - Tombol Focus Hold, AF/MF Switch <br />
                    - Diafragma Lingkaran Sembilan Bilah
                </div>
            ),
            
            price: "Rp350,000/day",
        },
        {
            id: 40,
            category: "Lensa",
            image: [id40],
            title: "Lensa Sony FE 100mm f/2.8 STF GM OSS",
            description:
            (
                <div>
                    - E-Mount Lens/Full-Frame Format <br />
                    - Aperture Range: f/2.8 to 20 | T5.6 to 22 <br />
                    - Apodization Element for Smooth Bokeh <br />
                    - One Aspherical Element & One ED Element <br />
                    - Nano AR Coating <br />
                    - Direct Drive Super Sonic Wave AF Motor <br />
                    - Optical SteadyShot Image Stabilization <br />
                    - Physical Aperture Ring; De-Click Switch <br />
                    - Macro Switching Ring; Focus Hold Button <br />
                    - Eleven-Blade Circular Diaphragm
                </div>
            ),
            
            price: "Rp375,000/day",
        },
        {
            id: 41,
            category: "Lensa",
            image: [id41],
            title: "Lensa Sony FE 100-400 f/4.5-5.6 STF GM OSS",
            description:
            (
                <div>
                    - Sony FE 100-400mm f/4.5-5.6 GM OSS Lens <br />
                    - Sony ALC-F77S 77mm Front Lens Cap <br />
                    - Sony ALC-R1EM Rear Lens Cap <br />
                    - ALC-SH151 Lens Hood <br />
                    - Tripod Mount
                </div>
            ),            
            price: "Rp550,000/day",
        },
        {
            id: 42,
            category: "Lensa",
            image: [id42],
            title: "Lensa Sony FE 135mm f/1.8 GM",
            description:
            (
                <div>
                    - ARAK FOKUS MINIMUM = 0,7 m (2,3 ft) <br />
                    - RASIO PERBESARAN MAKSIMUM (X) = 0,25 <br />
                    - DIAMETER FILTER (MM) = 82 <br />
                    - BERAT = 950 g (33,6 oz.)
                </div>
            ),
            
            price: "Rp400,000/day",
        },
        {
            id: 44,
            category: "Audio",
            image: [id44],
            title: "Sennheiser EW 100 G3 Wireless Clip On",
            description:
            (
                <div>
                    - Mini Jack to Mini Jack Cable (1x) <br />
                    - XLR to XLR Cable (1x) <br />
                    - Hotshoe (1x) <br />
                    - Mic (1x)
                </div>
            ),
            
            price: "Rp175,000/day"
        },
        {
            id: 45,
            category: "Audio",
            image: [id45],
            title: "Sennheiser EW 112P G4 Wireless Clip On",
            description:
            (
                <div>
                    - EK 100 G4 Camera-Mountable Receiver <br />
                    - SK 100 G4 Bodypack Transmitter <br />
                    - ME 2-II Omnidirectional Clip-On Lav Mic <br />
                    - 1680 Tunable Frequencies Across 42 MHz <br />
                    - Auto Frequency Scan Finds Open Bands <br />
                    - 3-Level Squelch to Block Interference <br />
                    - 20 Banks with 12 Channel Presets Each <br />
                    - Compander for Clearer Sound <br />
                    - Auto-Lock Prevents Accidental Adjustment <br />
                    - Not including battery
                </div>
            ),
            
            price: "Rp200,000/day"
        },
        {
            id: 46,
            category: "Audio",
            image: [id46],
            title: "Sennheiser SKP 100 G3 Plug On Transmitter",
            description:
                "*Not Including Battery",
            price: "Rp200,000/day"
        },
        {
            id: 47,
            category: "Audio",
            image: [id47],
            title: "Sennheiser Boom Mic MKH-416",
            description:
            (
                <div>
                    - Mic Sennheiser MKH-416 (1x) <br />
                    - Pistol Grip (1x) <br />
                    - Windshield Basket (1x) <br />
                    - Windscreen Wool (1x) <br />
                    - Stick Boom/ Boom Pole (1x) <br />
                    - Audio Cable 15 Meter (2x)
                </div>
            ),
            
            price: "Rp250,000/day"
        },
        {
            id: 48,
            category: "Audio",
            image: [id47],
            title: "Sennheiser Boom Mic MKH-60",
            description:
            (
                <div>
                    - Mic Sennheiser MKH-60 (1x) <br />
                    - Pistol Grip (1x) <br />
                    - Windshield Basket (1x) <br />
                    - Windscreen Wool (1x) <br />
                    - Stick Boom/ Boom Pole (1x) <br />
                    - Audio Cable 15 Meter (2x)
                </div>
            ),
            
            price: "Rp300,000/day"
        },
        {
            id: 49,
            category: "Audio",
            image: [id49, id49_1],
            title: "Saramonic Blink 500 B2 Wireless Clip On",
            description:
            (
                <div>
                    - 2 Transmitter (TX) + 1 Receiver (RX) <br />
                    - Lavalier Microphones (2x) <br />
                    - USB Charger Cable (3x) <br />
                    - USB Charger Adapter (1x) <br />
                    - Camera AUXX Cable (1x) <br />
                    - Smartphone AUX Cable (1x)
                </div>
            ),
            
            price: "Rp175,000/day"
        },
        {
            id: 50,
            category: "Audio",
            image: [id50, id50_1],
            title: "Saramonic Blink 500 B4 Wireless Clip On",
            description:
            (
                <div>
                    - For Journalists, Vloggers, YouTubers <br />
                    - 2 x Omni Lav Mics & Built-In Omni Mics <br />
                    - 2 x Ultracompact Clip-On Transmitters <br />
                    - Ultracompact 2-Channel Plug-In Receiver <br />
                    - Lightning Connector for iOS Devices & USB-C Device <br />
                    - Easy to Use, Plug & Play Operation <br />
                    - Adjustable Gain via Simple Buttons <br />
                    - TX: USB-Rechargeable 5-Hour Battery <br />
                    - RX: No Battery or Charging Required <br />
                    - 18 Systems at Once Max, up to 164' Range
                </div>
            ),
            
            price: "Rp200,000/day"
        },
        {
            id: 51,
            category: "Audio",
            image: [id51],
            title: "Audio Headphone",
            description:
                "Headphones ",
            price: "Rp100,000/day"
        },
        {
            id: 52,
            category: "Audio",
            image: [id52],
            title: "Sound Devices 552 Portable 5-Channel ",
            description:
                "Sound Devices 552 Portable 5-Channel Production Mixer",
            price: "Rp350,000/day"
        },
        {
            id: 53,
            category: "Audio",
            image: [id53, id53_1],
            title: "Audio Recorder Zoom H6N (Basic)",
            description:
            (
                <div>
                    - An extremely flexible 6-in/2-out mobile recording system <br />
                    - 4 x XLR/TRS combination inputs with microphone preamps and phantom power (+12/+24/+48V) provide ample connections <br />
                    - Modular input connection lets you attach onboard microphones and additional input jacks <br />
                    - Gain knobs for each input make setting levels fast and easy <br />
                    - Included X/Y and Mid-Side stereo microphone modules <br />
                    - Captures audio at up to 24-bit/96kHz professional-grade resolution <br />
                    - Doubles as a handy USB recording interface with your Mac, Windows PC, or iPad (via Apple Camera Connection Kit, not included) <br />
                </div>
            ),
            
            price: "Rp200,000/day"
        },
        {
            id: 54,
            category: "Audio",
            image: [id54, id54_1],
            title: "Zoom H8 8-Input / 12-Track Portable Handy Recorder",
            description:
            (
                <div>
                    - Podcast/Music/Field Recording Modes <br />
                    - Swappable X/Y Stereo Mic Capsule <br />
                    - 4 x XLR Mic Inputs with Pads and +48V <br />
                    - 2 x XLR-1/4" Mic/Line/Hi-Z Inputs <br />
                    - Intuitive Color Touchscreen <br />
                    - 3.5mm Line & Headphone Outputs <br />
                    - Onboard Compressor & Low-Cut Filter <br />
                    - Powered via Batteries, AC, or USB <br />
                    - Supports SDXC Cards up to 512GB <br />
                    - Stereo Safety Track Recording <br />
                    - Not including battery
                </div>
            ),
            
            price: "Rp250,000/day"
        },
        {
            id: 55,
            category: "Audio",
            image: [id55, id55_1, id55_2, id55_3, id55_4],
            title: "Audio Recorder Zoom F8 Multi Track",
            description:
            (
                <div>
                    - 8x XLR/TRS Combo Jacks with +75 dB Gain <br />
                    - Up to 192 kHz/24-Bit PCM Recording <br />
                    - Record Up to 10 Tracks Simultaneously <br />
                    - Dual Channel Recordings at Two Levels <br />
                    - Works with Zoom Microphone Capsules <br />
                    - 2x SDXC Card Slots <br />
                    - Built-In Slate Microphone <br />
                    - AC, External DC Adaptor <br />
                    - Supports SMPTE Timecode I/O <br />
                    - +24V/+48V Phantom Power <br />
                    - Not including battery
                </div>
            ),
            
            price: "Rp350,000/day"
        },
        {
            id: 56,
            category: "Audio",
            image: [id56],
            title: "Rode Videomic Rycote",
            description:
                "RODE VideoMic Rycote",
            price: "Rp125,000/day"
        },
        {
            id: 57,
            category: "Audio",
            image: [id57],
            title: "Kabel Audio XLR",
            description:
                "Kabel XLR",
            price: "Rp100,000/day"
        },
        {
            id: 58,
            category: "Audio",
            image: [id58],
            title: "Hollyland Solidcom (8 headset + 1 Basic)",
            description:
            (
                <div>
                    - Wide Frequency Response for Clear Audio <br />
                    - 8 True-Wireless Headsets, No Bodypacks <br />
                    - HUB with Wired Headset <br />
                    - Comfortable to Wear All Day Long <br />
                    - 1000' 2-Way Operating Range <br />
                    - DECT 6.0 for Stability and Security <br />
                    - Instant Pairing, Easy to Use <br />
                    - Acoustic Echo Cancellation <br />
                    - Includes HUB, Batteries, and Charger <br />
                    - Cascade Additional HUBs to Expand System
                </div>
            ),
            
            price: "Rp1,250,000/day"
        },
        {
            id: 60,
            category: "Lighting",
            image: [id60, id60_1],
            title: "ARRI M Series M40 With Ballast High Speed",
            description:
            (
                <div>
                    - Electronic Ballast <br />
                    - Active Line Filter <br />
                    - DMX Support <br />
                    - Four-Leaf Barndoor
                </div>
            ),
            price: "Rp1500,000/day"
        },
        {
            id: 61,
            category: "Lighting",
            image: [id61, id61_1, id61_2, id61_3],
            title: "ARRI M Series M18 With Ballast High Speed",
            description:
            (
                <div>
                    - 1 x M18 HMI Lamp Head <br />
                    - 1 x EB MAX High Speed Ballast <br />
                    - 1 x Head-to-Ballast Cable <br />
                    - 1 x Four-Way Barndoor Set <br />
                    - High Output Reflector, 16.8" Diameter <br />
                    - 1200 Hz High-Speed Filming Capability <br />
                    - DMX Control, 50 to 100% Dimming <br />
                    - Active Line Filter (ALF) <br />
                    - Compensation of Cable Losses (CCL) <br />
                    - 300 Hz: 270-360 Hz, 1000 Hz: 900-1200 Hz
                </div>
            ),
            
            price: "Rp1,000,000/day"
        },
        {
            id: 62,
            category: "Lighting",
            image: [id62],
            title: "ARRI HMI 4,000 Kw Parlight ",
            description:"ARRI HMI 4000 WATT FF PARLIGHT",
            price: "Rp800,000/day"
        },
        {
            id: 63,
            category: "Lighting",
            image: [id63],
            title: "ARRI HMI 2500 Watt Flicker Free ",
            description:
            (
                <div>
                    - 2500W Fixture with 10" Fresnel Lens <br />
                    - 2500 Electronic Ballast <br />
                    - DMX Protocol Support <br />
                    - Active Line Filter <br />
                    - Four Leaf Barndoor <br />
                    - Filter Frame <br />
                    - 50' Head to Ballast Cable
                </div>
            ),
            
            price: "Rp400,000/day"
        },
        {
            id: 64,
            category: "Lighting",
            image: [id64, id64_1, id64_2, id64_3, id64_4, id64_5, id64_6],
            title: "Aputure Amaran F21C ",
            description:
            (
                <div>
                    - Panel Surface Area: 2 x 1' <br />
                    - Color Temperature: 2500-7500K <br />
                    - HSI Full Color Control <br />
                    - Green-Magenta Adjustment <br />
                    - 15 Built-In Lighting FX, 46 Gel Presets <br />
                    - CRI 95, TLCI 98, CQS 96 <br />
                    - 100W Output <br />
                    - 0-100% Dimming, DMX Control <br />
                    - Multi-Voltage AC, V-Mount Battery <br />
                    - Softbox Frame with Two Diffusers & Grid
                </div>
            ),
            
            price: "Rp350,000/day"
        },
        {
            id: 65,
            category: "Lighting",
            image: [id65, id65_1, id65_2, id65_3, id65_4],
            title: "Aputure Amaran F22C ",
            description:
            (
                <div>
                    - Panel Surface Area: 2 x 2' <br />
                    - Color Temperature: 2500-7500K <br />
                    - HSI Full Color Control <br />
                    - Green-Magenta Adjustment <br />
                    - 15 Built-In Lighting FX, 46 Gel Presets <br />
                    - CRI 95, TLCI 98, CQS 96 <br />
                    - 200W Output <br />
                    - 0-100% Dimming, DMX Control <br />
                    - Multi-Voltage AC, V-Mount Battery <br />
                    - Softbox Frame with Two Diffusers & Grid
                </div>
            ),
            
            price: "Rp400,000/day"
        },
        {
            id: 66,
            category: "Lighting",
            image: [lighting1],
            title: "Aputure Amaran 5 Inch",
            description:
                "",
            price: "Rp125,000/day"
        },
        {
            id: 67,
            category: "Lighting",
            image: [id67, id67_1, id67_2, id67_3, id67_4],
            title: "Aputure LS 300D Mark II (Kit)",
            description:
            (
                <div>
                    - 1 x Light Storm 300d II Light Head <br />
                    - 1 x Standard Reflector <br />
                    - 1 x Ballast + Controller Box With V Mount Battery Plates <br />
                    - 1 x Control Box to Head Cable <br />
                    - 1 x Aputure Wireless Remote Control <br />
                    - 1 x AC Power Supply/Ballast to Control Box XLR Cable <br />
                    - 1 x Light Stand/C-Stand *Optional <br />
                    - 1 x Carrying Case
                </div>
            ),            
            price: "Rp550,000/day"
        },
        {
            id: 68,
            category: "Lighting",
            image: [id68],
            title: "Aputure LS 600D Pro (Kit)",
            description:
            (
                <div>
                    - 600W Daylight-Balanced Professional Bowens Mount Point-Source LED <br />
                    - Comparable to 1,200W HMI: 8,500+ lux @ 3m with LS 600 Series Hyper Reflector <br />
                    - AC & Battery Power Options: Dual Multi-Voltage Battery Plate Power Supply <br />
                    - Battery Charging: Can Charge Gold/V-Mount Batteries When Plugged into AC Power <br />
                    - Sidus Link, LumenRadio CRMX, Art-net & sACN via etherCON, 5-Pin DMX512
                </div>
            ),
            
            price: "Rp600,000/day"
        },
        {
            id: 69,
            category: "Lighting",
            image: [id69],
            title: "Aputure LS 1200D (kit)",
            description:
            (
                <div>
                    - 5600K Color Temperature, 95 CRI/TLCI <br />
                    - 15, 30, and 45° Reflectors Included <br />
                    - Wireless DMX, Bluetooth Control <br />
                    - 4 Dimming Modes, Comparable to 1600W HMI
                </div>
            ),
            price: "Rp850,000/day"
        },
        {
            id: 70,
            category: "Lighting",
            image: [id70, id70_1, id70_2],
            title: "Aputure NOVA P600C RGB",
            description:
            (
                <div>
                    - 2000-10,000K Variable Color Temperature <br />
                    - CRI/TLCI: 95+, Full RGB Adjustments <br />
                    - Twice the Brightness of P300c, 2298+ Lux <br />
                    - Kit Includes Hard-Shell Wheeled Case <br />
                    - Hue, Saturation, Light Intensity Control <br />
                    - DMX512, Bluetooth, Sidus Link Control <br />
                    - Plus/Minus Green Control, Control Box <br />
                    - 3-Pin XLR 48V/15A Battery Power <br />
                    - 19 Special Effects, Dimming: 0.1-100% <br />
                    - 300+ Gel Presets, Junior Pin Yoke Mount
                </div>
            ),
            
            price: "Rp850,000/day"
        },
        {
            id: 71,
            category: "Lighting",
            image: [id71],
            title: "Aputure Spotlight 19\"",
            description:
            (
                <div>
                    - Bowens Mount <br />
                    - Included 19° Lens <br />
                    - Dual Drop-In Slots <br />
                    - Aluminum Alloy Housing
                </div>
            ),
            
            price: "Rp200,000/day"
        },
        {
            id: 72,
            category: "Lighting",
            image: [id72],
            title: "Aputure Fresnel",
            description:
            (
                <div>
                    - 65cm / 26in Omni-Directional Soft Box <br />
                    - 2-Second Quick Set Up Design <br />
                    - 270º Beam Angle <br />
                    - Included 1-Stop Diffusion <br />
                    - Included Fabric Light Control Skirt
                </div>
            ),
            
            price: "Rp100,000/day"
        },
        {
            id: 73,
            category: "Lighting",
            image: [id73, id73_1],
            title: "Aputure Latern",
            description:
            (
                <div>
                    - Carbon slider video rail 120 cm <br />
                    - Smooth, fluid movement of the camera <br />
                    - Height adjustable, can also be mounted on a tripod <br />
                    - 120 cm usable rail part
                </div>
            ),
            
            price: "Rp150,000/day"
        },
        {
            id: 74,
            category: "Lighting",
            image: [id74],
            title: "Godox TL120 SET",
            description:
            (
                <div>
                    - 4 Tube Lights with Built-In Batteries <br />
                    - Color: 2700-6500K <br />
                    - CRI/TLCI: 96/98 <br />
                    - Built-In Rechargeable Battery <br />
                    - 0-100% Dimming & RGB Control <br />
                    - Phone, DMX, 2.4 GHz Wireless Control <br />
                    - 98-164' Wireless Range <br />
                    - 32 Channels, 6 Groups <br />
                    - Remote Control & Carry Case
                </div>
            ),            
            price: "Rp700,000/day"
        },
        {
            id: 75,
            category: "Lighting",
            image: [id75],
            title: "Godox TL60 SET",
            description:
            (
                <div>
                    - 4 Tube Lights w/ Power Adapters <br />
                    - Color: 2700-6500K <br />
                    - CRI/TLCI: 96/98 <br />
                    - RGB w/ HSI Control, 40 Built-In Filters <br />
                    - Length: 29.5" ~ 75cm <br />
                    - Battery Runtime: 2 Hours <br />
                    - Phone, DMX, 2.4 GHz Wireless Control <br />
                    - Dims 0-100%, 98-164' Wireless Range <br />
                    - 32 Channels, 6 Groups <br />
                    - Power Adapter, DC Cable, Carry Bag
                </div>
            ),            
            price: "Rp500,000/day"
        },
        {
            id: 76,
            category: "Lighting",
            image: [id76],
            title: "Godox FL100 Flexible LED 40x60 cm",
            description:
            (
                <div>
                    - Flexible LED Light, Controller <br />
                    - X-Mount Holder, Stand Bracket <br />
                    - Wireless Remote Control, Carry Case <br />
                    - Color: 3300 to 5600K <br />
                    - CRI/TLCI: 96/98 <br />
                    - 10 to 100% Dimming
                </div>
            ),            
            price: "Rp275,000/day"
        },
        {
            id: 77,
            category: "Lighting",
            image: [id77],
            title: "Godox FL150 Flexible LED 30x120 cm",
            description:
            (
                <div>
                    - Power Supplied by DC: AC100V-240V (50/60Hz); 3.5A (DC 16.8V/10A) <br />
                    - Power Consumption: 150W <br />
                    - Colour Temperature: 3300 +-200K to 5600 +-200 K <br />
                    - 100% Illuminance (LUX) (1m, dark room): less than 4300 LUX <br />
                    - TLCI: higher than 98 <br />
                    - CRI: higher than 96 <br />
                    - Brightness Range: 10% to 100% <br />
                    - Operation Temperature: -10 to 45 degrees C <br />
                    - Channels: 16 <br />
                    - Groups: 6 (A/B/C/D/E/F) <br />
                    - Range of the Wireless Remote Control: less than 30 m <br />
                    - Range of the Bluetooth: less than 50 m <br />
                    - Output Connector: 4 Pin <br />
                    - Dimensions: 30x120cm <br />
                    - Weight: 4.37kg
                </div>
            ),
            
            price: "Rp275,000/day"
        },
        {
            id: 78,
            category: "Lighting",
            image: [lighting1],
            title: "LED Light Panel 1x1",
            description:
                "",
            price: "Rp250,000/day"
        },
        {
            id: 80,
            category: "Light Accessories",
            image: [id80],
            title: "Frames (1 x1 m , 1x2m)",
            description:
                "Frame ukuran 1x1m, 1x2m",
            price: "Rp50,000/day"
        },
        {
            id: 81,
            category: "Light Accessories",
            image: [id81],
            title: "Cutterlight Set",
            description:
                "Black Flag Kit / Cutterlight / Light Control Flag",
            price: "Rp100,000/day"
        },
        {
            id: 82,
            category: "Light Accessories",
            image: [id82],
            title: "Overlenght",
            description:
                "Extension Cable / Overlength Cable / Kabel Perleng (10m)",
            price: "Rp25,000/day"
        },
        {
            id: 83,
            category: "Light Accessories",
            image: [lighting1],
            title: "Panel Box",
            description:
                "",
            price: "Rp25,000/day"
        },
        {
            id: 84,
            category: "Light Accessories",
            image: [id84],
            title: "Floppy",
            description:
                "Floppy Cutter 4x4 feet (Floppy Flag)",
            price: "Rp125,000/day"
        },
        {
            id: 85,
            category: "Light Accessories",
            image: [id85],
            title: "Tracing Frame + Filter",
            description:
            (
                <div>
                    - Size: 4x4 FT (120cm x 120cm) <br />
                    - 1 Set = 4 Pcs
                </div>
            ),
            
            price: "Rp125,000/day"
        },
        {
            id: 86,
            category: "Light Accessories",
            image: [id86],
            title: "Reflector",
            description:
            (
                <div>
                    - Can be used as a diffuser or reflector. <br />
                    - Fold-up design opens and closes quickly
                </div>
            ),            
            price: "Rp30,000/day"
        },
        {
            id: 87,
            category: "Light Accessories",
            image: [id87],
            title: "Sandbag",
            description:
                "Sandbag / Shotbag",
            price: "Rp10,000/day"
        },
        {
            id: 89,
            category: "Equipment List",
            image: [id89, id89_1],
            title: "DJI Phantom 4 (No Pilot)",
            description:
                "DJI Phantom 4 Body Full set - DJI Phantom 4 Body Upper Middle",
            price: "Rp1,200,000/day"
        },
        {
            id: 90,
            category: "Equipment List",
            image: [id90, id90_1],
            title: "DJI Mavic 2 Pro (No Pilot)",
            description:
            (
                <div>
                    - Hasselblad 20MP / UHD 4K Gimbal Camera <br />
                    - 1" CMOS + f/2.8-f/11 Adjustable Aperture <br />
                    - 10-Bit Dlog-M + 10-Bit HDR Video <br />
                    - OcuSync 2.0 Transmission Technology <br />
                    - Up to 31 Minutes Flight Time <br />
                    - Top Speed of 44.7 mph <br />
                    - Omnidirectional Obstacle Sensing <br />
                    - Hyperlapse Time-Lapse Mode <br />
                    - Enhanced HDR Photos with 13 EV
                </div>
            ),            
            price: "Rp2,000,000/day"
        },
        {
            id: 91,
            category: "Equipment List",
            image: [id91, id91_1],
            title: "DJI Mavic 3 Classic (No Pilot)",
            description:
            (
                <div>
                    - DJI RC Remote Included <br />
                    - 20MP 5.1K Wide-Angle 4/3 CMOS Hasselblad <br />
                    - 4K Slow Motion at 120 fps <br />
                    - 10-Bit D-Log Color Profile & HNCS <br />
                    - f/2.8 to f/11 Adjustable Aperture <br />
                    - Omnidirectional Obstacle Avoidance <br />
                    - Up to 9.3-Mile HD Transmission Range <br />
                    - Advanced Return-to-Home Function <br />
                    - Automated Shooting Modes <br />
                    - Up to 46 Minutes of Flight Time
                </div>
            ),            
            price: "Rp2,500,000/day"
        },
        {
            id: 92,
            category: "Equipment List",
            image: [id92, id92_1],
            title: "DJI Mavic 3 Cine Premium (no Pilot)",
            description:
            (
                <div>
                    - 3-Axis Gimbal with Dual Cameras <br />
                    - 20MP 5.1K Wide-Angle 4/3 CMOS Hasselblad <br />
                    - 12MP Telephoto with 28x Hybrid Zoom <br />
                    - Up to 46 Minutes of Flight Time <br />
                    - Up to 9.3-Mile Transmission Range <br />
                    - 360° Obstacle Avoidance System <br />
                    - Apple ProRes 422 HQ & 10-Bit D-Log <br />
                    - 1080p60 Live View Video Stream <br />
                    - Built-In Storage with a 1TB SSD <br />
                    - RC Pro Remote with 5.5" Display & O3+
                </div>
            ),
            price: "Rp5,000,000/day"
        },
        {
            id: 93,
            category: "Equipment List",
            image: [id93, id93_1],
            title: "Dji Ronin RS2 Pro Combo",
            description:
            (
                <div>
                    - DJI RS 2 Gimbal Stabilizer <br />
                    - DJI Ronin-S Extended Grip/Tripod <br />
                    - BG30 Grip <br />
                    - DJI R Multi-Camera Control Cable (USB Type-C) <br />
                    - DJI R Multi-Camera Control Cable (Sony Multi) <br />
                    - DJI R Multi-Camera Control Cable (Micro-USB) <br />
                    - DJI R Multi-Camera Control Cable (Mini-USB) <br />
                    - DJI R Briefcase Handle <br />
                    - DJI R Quick-Release Plate (Lower) <br />
                    - DJI R Quick-Release Plate (Upper) <br />
                    - DJI R Lens-Fastening Strap
                </div>
            ),
            
            price: "Rp650,000/day"
        },
        {
            id: 94,
            category: "Equipment List",
            image: [id94, id94_1],
            title: "Dji Ronin RS3 Pro Combo",
            description:
                "The DJI RS3 Pro Gimbal Stabilizer Combo has been redesigned to be sleeker and more powerful than the Ronin RS 2 gimbal, with its carbon fiber body weighing only 3.3 lb and a payload of up to 10 lb. This compact gimbal is tough enough to support and stabilize midsize cameras such as the Sony FX6 or Canon C70 with a 24-70mm zoom lens attached. With a larger full-color OLED display, a new fine-tuning knob, and greater stability, the RS 3 Pro can become an essential part of your professional camera kit. The Combo kit includes additional essentials such as an updated focus motor, a motor rod mount kit with gear strip, a briefcase handle, a BG30 grip, a lower extended quick release plate, a wireless Ronin Image Transmitter, a phone holder, USB multicamera control cables, multiple HDMI cables, power cables, and a lens-fastening strap.",
            price: "Rp750,000/day"
        },
        {
            id: 95,
            category: "Equipment List",
            image: [id95],
            title: "Zhiyun Crane 3-Lab",
            description:
            (
                <div>
                    - Load capacity: 4.5kg (10lb) <br />
                    - Axes: 3-axis (pitch, roll, and yaw) <br />
                    - Rotation range: 360° <br />
                    - Articulated second handgrip <br />
                    - 1080p HDMI WiFi image transmission <br />
                    - Smartphone monitor & remote control <br />
                    - Focus & Zoom motor control options <br />
                    - Timelapse & motion lapse control <br />
                    - Auto-follow object mode <br />
                    - Batteries: 4x 18650 <br />
                    - Battery life: Up to 18 hours
                </div>
            ),
            
            price: "Rp350,000/day"
        },
        {
            id: 96,
            category: "Equipment List",
            image: [id96],
            title: "Portha Jib (1.5 meter)",
            description:
                "Portha Jib 1.5 Meter (Egripment)",
            price: "Rp750,000/day"
        },
        {
            id: 97,
            category: "Equipment List",
            image: [id97],
            title: "40\" C-Stand",
            description:
            (
                <div>
                    - 2.5" Grip Head and 40" Arm <br />
                    - Chrome-plated Construction <br />
                    - 3 Sections, 2 Risers <br />
                    - Detachable Base <br />
                    - 40" Main Section
                </div>
            ),            
            price: "Rp100,000/day"
        },
        {
            id: 98,
            category: "Equipment List",
            image: [id98],
            title: "20\" C-Stand",
            description:
                "C-Stand Mini 20\" Kupo Master + Arm (Turtle Base)",
            price: "Rp50,000/day"
        },
        {
            id: 99,
            category: "Equipment List",
            image: [id99],
            title: "Magic Arm ",
            description:
                "Super Clamp & Magic Arm",
            price: "Rp40,000/day"
        },
        {
            id: 100,
            category: "Equipment List",
            image: [id100],
            title: "Alligator Clamp",
            description:
                "Alligator Clamp",
            price: "Rp20,000/day"
        },
        {
            id: 101,
            category: "Equipment List",
            image: [id101],
            title: "Butterfly Set 8x8 (Black Cloth, Silk, Ultra Bounce) & 2pcs C-Stand)",
            description:
            (
                <div>
                    - Frame 8x8 Feet (1 Pcs) <br />
                    - Kain Silk 8x8 Feet (1 Pcs) <br />
                    - Kain Black Net 8x8 Feet (1 Pcs) <br />
                    - Kain Black Solid 8x8 Feet (1 Pcs) <br />
                    - Kain Green Screen 8x8 Feet (1 Pcs) <br />
                    - C-Stand (2 Pcs)
                </div>
            ), 
            price: "Rp50,000/day"
        },
        {
            id: 102,
            category: "Equipment List",
            image: [id101],
            title: "Butterfly Set 6x6 (Black Cloth, Silk, Ultra Bounce, Net) & 2pcs C-Stand",
            description:
            (
                <div>
                    - Frame 6x6 Feet (4 Pcs) <br />
                    - Kain Silk 6x6 Feet (4 Pcs) <br />
                    - Kain Black Net 6x6 Feet (1 Pcs) <br />
                    - Kain Black Solid 6x6 Feet (1 Pcs) <br />
                    - Kain Green Screen 6x6 Feet (1 Pcs) <br />
                    - C-Stand (2 Pcs)
                </div>
            ),            
            price: "Rp450,000/day"
        },
        {
            id: 103,
            category: "Equipment List",
            image: [id103],
            title: "Slider 120Cm",
            description:
            (
                <div>
                    - Carbon slider video rail 120 cm <br />
                    - Smooth, fluid movement of the camera <br />
                    - Height adjustable, can also be mounted on a tripod <br />
                    - 120 cm usable rail part
                </div>
            ),
            
            price: "Rp200,000/day"
        },
        {
            id: 104,
            category: "Equipment List",
            image: [id104],
            title: "Applebox (Set Of 5)",
            description:
                "1 Set (Isi 5)",
            price: "Rp100,000/day"
        },
        {
            id: 105,
            category: "Equipment List",
            image: [id105],
            title: "Tripod",
            description:
                "Tripod E-Image Bowl 50mm (GH03)",
            price: "Rp100,000/day"
        },
        {
            id: 106,
            category: "Equipment List",
            image: [id106],
            title: "Monopod",
            description:
            (
                <div>
                    - Monopod Manfrotto 682B <br />
                    - Ball Head 496RC2
                </div>
            ),            
            price: "Rp75,000/day"
        },
        {
            id: 107,
            category: "Equipment List",
            image: [id107],
            title: "Statif/Light Stand",
            description:
                "Is black aluminium lighting stand that designed to support larger light banks and heads up to a maximum of 9.5kg. The height can be adjusted between 102 and 264cm and is also available as a set of two complete with carry bag.",
            price: "Rp20,000/day"
        },
        {
            id: 108,
            category: "Equipment List",
            image: [id108],
            title: "V-Mount Battery",
            description:
            (
                <div>
                    - Li-ion battery, no memory effect <br />
                    - 14.4V, 95Wh large capacity <br />
                    - D-tap output socket <br />
                    - 4-level LED power indicator <br />
                    - Metal V-mount plate <br />
                    - Wireless receiver mount <br />
                    - Safe air transportation
                </div>
            ),
            price: "Rp100,000/day"
        },
        {
            id: 109,
            category: "Equipment List",
            image: [id109],
            title: "Charger V-Mount",
            description: "V-Mount Charger 2 Slot",
            price: "Rp50,000/day"
        },
        {
            id: 111,
            category: "Equipment List",
            image: [id111, id111_1],
            title: "Monitor Director CINEGEARS Ruige 21.5\" With Case",
            description:
            (
                <div>
                    - 21.5" WLED backlit <br />
                    - Pixel resolusi 1920 x 1080 <br />
                    - Input SDI dan HDMI, dengan SDI out <br />
                    - 8 bit, 16.7jt warna <br />
                    - Image processor 10 bit <br />
                    - Brightness 250 cd/m <br />
                    - Contrast Ratio 1000:1 <br />
                    - Response time 7ms <br />
                    - Berat 9.7 kg <br />
                    - Dimensi: 514.8 x 328 x 40mm <br />
                    - Daya maksimal 22W
                </div>
            ),            
            price: "Rp350,000/day"
        },
        {
            id: 112,
            category: "Equipment List",
            image: [id112, id112_1],
            title: "Monitor Director Bestview SP17 17\" 4K Multi View",
            description:
            (
                <div>
                    - Display type: Ratio of 16: 9 <br />
                    - Resolution: 3840*2160 <br />
                    - Brightness: 400CD/M² <br />
                    - Ratio: 1000:1 <br />
                    - View angle: L/R 178°(89°/89°)，U/D 178°(89°/89°) <br />
                    - Input signal: HDMI/3G-SDI/VGA/DVI <br />
                    - Output: 3G-SDI, AV composite <br />
                    - AUDIO Audio R.L two-channel output
                </div>
            ),
            
            price: "Rp350,000/day"
        },
        {
            id: 113,
            category: "Equipment List",
            image: [id113, id113_1],
            title: "FeelWorld F5 5.0\" Monitor (4K Support and Tilt Arm)",
            description:
            (
                <div>
                    - 1920 x 1080 Native Resolution <br />
                    - Input Support up to DCI 4K <br />
                    - HDMI Input and Output Ports <br />
                    - 1000:1 Contrast, 450 cd/m² Brightness <br />
                    - Calibrated for Rec. 709 HD Color <br />
                    - 80° Viewing Angle <br />
                    - 3.5mm Stereo Headphone Jack <br />
                    - Powered by F970 or LP-E6 Battery <br />
                    - 180° Tilt Arm with Cold Shoe <br />
                    - Sun Shade Included
                </div>
            ),
            
            price: "Rp200,000/day"
        },
        {
            id: 114,
            category: "Equipment List",
            image: [id114, id114_1],
            title: "Atomos Ninja V 5\" 4K HDMI Recording Monitor (Include SSD)",
            description:
            (
                <div>
                    - 1920 x 1080 Touchscreen Display <br />
                    - 10-Bit Exterior Viewable Monitor <br />
                    - 4K HDMI Input <br />
                    - 1000 cd/m² Brightness <br />
                    - AtomHDR Mode with 10-Stop Dynamic Range <br />
                    - 10-Bit 4:2:2 ProRes & DNxHR Encoding <br />
                    - Records to 2.5" Mini or Standard SSDs <br />
                    - Supports a Variety of Log Formats <br />
                    - Single Sony L-Series Battery Slot
                </div>
            ),
            
            price: "Rp400,000/day"
        },
        {
            id: 115,
            category: "Equipment List",
            image: [id115, id115_1, id115_2, id115_3],
            title: "Atomos Shogun Inferno 7\" 4K HDMI/Quad 3G-SDI/12G-SDI + SSD",
            description:
            (
                <div>
                    - 1920 x 1200 Touchscreen Display <br />
                    - 10-Bit FRC Monitor Processing <br />
                    - 4K HDMI and 12G-SDI Inputs/Outputs <br />
                    - 1500 cd/m² Brightness <br />
                    - HDR Mode with 10-Stop Dynamic Range <br />
                    - High Bright Mode for Rec. 709 Viewing <br />
                    - UHD 4K up to 60p / 1080p up to 240 fps <br />
                    - 10-Bit 4:2:2 ProRes & DNxHR Encoding <br />
                    - Records to 2.5" SSD/HDD Media <br />
                    - Dual Sony L-Series Battery Slots
                </div>
            ),            
            price: "Rp500,000/day"
        },
        {
            id: 116,
            category: "Equipment List",
            image: [id116, id116_1],
            title: "Monitor Feelworld Master 7” Ultra Bright",
            description:
            (
                <div>
                    - 7" 1920 x 1200 IPS LCD Monitor <br />
                    - HDMI 1.4 and 3G-SDI Loop-Through I/O <br />
                    - Accepts SD, HD, and UHD 4K Video <br />
                    - 2200 cd/m² Brightness <br />
                    - 1200:1 Contrast Ratio <br />
                    - 160° Viewing Angle <br />
                    - Edge-Mounted Control Buttons <br />
                    - Includes Removable L-Series Plate <br />
                    - Image Evaluation Tools <br />
                    - 1:1 Pixel Mapping
                </div>
            ),
            price: "Rp250,000/day"
        },
        {
            id: 117,
            category: "Equipment List",
            image: [id117, id117_1, id117_2, id117_3],
            title: "Hollyland Mars 4K Wireless Video Transmission System",
            description:
            (
                <div>
                    - Transmitter and Receiver Set <br />
                    - Transmit up to UHD 4K30 Video up to 450' <br />
                    - SDI & HDMI Inputs & Outputs <br />
                    - L-Series Battery/USB Type-C/DC Power <br />
                    - Smart Channel Scanning, Touchscreen LCD <br />
                    - Low 0.06s Latency, 12 Mb/s Data Rate <br />
                    - DC, L-Series & USB Type-C Power Options <br />
                    - Transmits to up to 4 x Apps as Monitor
                </div>
            ),            
            price: "Rp500,000/day"
        },
        {
            id: 118,
            category: "Equipment List",
            image: [id118, id118_1],
            title: "Hollyland Cosmo C1 SDI/HDMI Wireless Video Transmission System",
            description:
            (
                <div>
                    - Cosmo C1 Transmitter & Cosmo C1 Receiver <br />
                    - 4 x Antenna & 2 x Mushroom Antenna <br />
                    - Pan/Tilt Mount with Shoe Mount <br />
                    - USB Type-C Adapter: 1 x DC Adapter & 1 x D-Tap to DC Cable <br />
                    - 6 x Battery NP-F770 <br />
                    - 1 x Charger NP-F Series <br />
                    - Kabel HDMI 1m/ SDI 1m (Sesuai Kebutuhan)
                </div>
            ),
            price: "Rp600,000/day"
        },
        {
            id: 119,
            category: "Equipment List",
            image: [id119],
            title: "Hollyland Mars 400S PRO SDI/HDMI Wireless Video Transmission System",
            description:
            (
                <div>
                    - Hollyland Mars 400S PRO SDI/HDMI Wireless Video Transmission System <br />
                    - Mars 400S PRO Transmitter <br />
                    - Mars 400S PRO Receiver <br />
                    - 5x Antenna <br />
                    - DC Power Adapter
                </div>
            ),
            
            price: "Rp400,000/day"
        },
        {
            id: 121,
            category: "Equipment List",
            image: [id121],
            title: "Battery Canon LP-E6",
            description:
                "Battery/batre canon LP-E6 for EOS 5D ,7D, 60D",
            price: "Rp45,000/day"
        },
        {
            id: 122,
            category: "Equipment List",
            image: [id122, id122_1],
            title: "Battery Sony NP-FZ100 (For Sony A9, A7 III, A7R III series)",
            description:
                (
                    <div>
                        - 7.2 V Output <br />
                        - 2280 mAh Capacity <br />
                        - 16.4 Wh<br />
                    </div>
                ),
            price: "Rp45,000/day"
        },
        {
            id: 123,
            category: "Equipment List",
            image: [id123],
            title: "Battery Sony NP-FW50",
            description:
                "Fits: Sony Alpha NEX-3, 5, 6 and 7 Series Cameras, DSLR-SLT-A33, DSLR-SLT-A55",
            price: "Rp35,000/day"
        },
        {
            id: 124,
            category: "Equipment List",
            image: [id124],
            title: "Battery Sony NP-F770",
            description:
                "Battery Sony NP-F770",
            price: "Rp50,000/day"
        },
        {
            id: 125,
            category: "Equipment List",
            image: [id125],
            title: "Charger Canon LC-E6 for LP-E6",
            description:
                "Charger Canon LC-E6 for LP-E6 Battery Pack",
            price: "Rp45,000/day"
        },
        {
            id: 126,
            category: "Equipment List",
            image: [id126],
            title: "Charger Sony for NP-FZ100",
            description:
                (
                    <div>
                        - Daya: AC, 100-240 V, 50 Hz / 60 Hz <br/>
                        - Waktu pengisian daya: Sekitar 150 menit
                    </div>
                ),
            price: "Rp45,000/day"
        },
        {
            id: 127,
            category: "Equipment List",
            image: [id127],
            title: "Charger Sony for NP-FW50",
            description:
                (
                    <div>
                        - For InfoLITHIUM NP-FW50 Battery <br />
                        - Retractable Plug<br />
                        - 100-240VAC Power for Worldwide Use
                    </div>
                ),
            price: "Rp45,000/day"
        },
        {
            id: 128,
            category: "Equipment List",
            image: [id128],
            title: "Sandisk SDXC EXTREME 64gb",
            description:
                "SanDisk Extreme SDXC UHS-I 64GB",
            price: "Rp45,000/day"
        },
        {
            id: 129,
            category: "Equipment List",
            image: [id129],
            title: "Sandisk MicroSD Extreme 64GB",
            description:
                "SanDisk Extreme MicroSD 64GB",
            price: "Rp45,000/day"
        },
        {
            id: 130,
            category: "Equipment List",
            image: [id130],
            title: "CFexpress Sony 80GB Type A TOUGH",
            description:
                "CFexpress Sony 80GB Type A TOUGH ",
            price: "Rp85,000/day"
        },
        {
            id: 131,
            category: "Equipment List",
            image: [id131],
            title: "Sony MRW-G2 CFexpress Type A/SD Memory Card Reader",
            description: (
                <div>
                    - For CFexpress Type A & SD Cards <br />
                    - USB 3.1 Gen 2 Interface <br />
                    - Data Transfer Rate: Up to 10 Gb/s <br />
                    - Includes USB Type-C and Type-A Cables <br />
                </div>
            ),
            price: "Rp125,000/day"
        },
        {
            id: 132,
            category: "Equipment List",
            image: [id132],
            title: "Cable BNC (100 Meter)",
            description:
                "Kabel 3G-SDI BNC to BNC (100 meter)",
            price: "Rp250,000/day"
        },
        {
            id: 133,
            category: "Equipment List",
            image: [id132],
            title: "Cable BNC (50 Meter)",
            description:
                "Kabel 3G-SDI BNC to BNC (50 meter)",
            price: "Rp150,000/day"
        },
        {
            id: 134,
            category: "Equipment List",
            image: [id134],
            title: "Cable XLR (50 Meter)",
            description:
                (
                    <div>
                        - Connector 1: XLR (F)<br />
                        - Type: Microphone<br />
                        - Connector 2: XLR (M)<br />
                        - Cable Length: 3 m<br />
                        - Colour: Black<br />
                        - Country of Production: Taiwan
                    </div>
                ),
            price: "Rp100,000/day"
        },
        {
            id: 135,
            category: "Equipment List",
            image: [id135],
            title: "Cable HDMI (10 Meter)",
            description:
                "Kabel HDMI (10 meter)",
            price: "Rp80,000/day"
        },
        {
            id: 136,
            category: "Equipment List",
            image: [id135],
            title: "Cable HDMI (5 Meter)",
            description:
                "Kabel HDMI (5 meter)",
            price: "Rp50,000/day"
        },
        {
            id: 137,
            category: "Equipment List",
            image: [id137],
            title: "ND Filter Variable",
            description:
                "Filter Variabel Ring",
            price: "Rp75,000/day"
        },
        {
            id: 138,
            category: "Equipment List",
            image: [id138],
            title: "Step Up Ring",
            description:
                "Step Up Ring (82mm to 52mm)",
            price: "Rp50,000/day"
        },
        {
            id: 139,
            category: "Equipment List",
            image: [id139],
            title: "Mobil Grandmax / Carry + BBM (Jakarta)",
            description: (
                <div>
                    - Warna Putih<br />
                    - Tahun Produksi 2012 akhir<br />
                    - Transmisi: Manual<br />
                    - BBM: Premium<br />
                    - Plat: B (Jakarta Selatan)<br />
                    - Silinder: 1300 cc<br />
                    - Audio: MP3 DVD/USB/Radio
                </div>
            ),
            price: "Rp500,000/day"
        },
        {
            id: 140,
            category: "Equipment List",
            image: [id139],
            title: "Mobil Grandmax / Carry Non BBM (Jakarta)",
            description: (
                <div>
                    - Warna Putih<br />
                    - Tahun Produksi 2012 akhir<br />
                    - Transmisi: Manual<br />
                    - BBM: Premium<br />
                    - Plat: B (Jakarta Selatan)<br />
                    - Silinder: 1300 cc<br />
                    - Audio: MP3 DVD/USB/Radio
                </div>
            ), price: "Rp400,000/day"
        },
        {
            id: 141,
            category: "Equipment List",
            image: [id139],
            title: "Mobil Grandmax / Carry + BBM (Bogor/Puncak)",
            description: (
                <div>
                    - Warna Putih<br />
                    - Tahun Produksi 2012 akhir<br />
                    - Transmisi: Manual<br />
                    - BBM: Premium<br />
                    - Plat: B (Jakarta Selatan)<br />
                    - Silinder: 1300 cc<br />
                    - Audio: MP3 DVD/USB/Radio
                </div>
            ),
            price: "Rp600,000/day"
        },
        {
            id: 142,
            category: "Equipment List",
            image: [id139],
            title: "Mobil Grandmax / Carry + BBM (Luar Kota)",
            description: (
                <div>
                    - Warna Putih<br />
                    - Tahun Produksi 2012 akhir<br />
                    - Transmisi: Manual<br />
                    - BBM: Premium<br />
                    - Plat: B (Jakarta Selatan)<br />
                    - Silinder: 1300 cc<br />
                    - Audio: MP3 DVD/USB/Radio
                </div>
            ),
            price: "Rp-/day"
        },
        {
            id: 143,
            category: "Equipment List",
            image: [lighting1],
            title: "Crew Pengawal Alat",
            description:
                "",
            price: "Rp350,000/day"
        }
    ];

    const product = products.find((product) => product.id === parseInt(id, 10));

    if (!product) {
        return <div>Produk tidak ditemukan</div>;
    }

    const handleImageClick = (image) => {
        setSelectedImage(image);
    };

    return (
        <div style={{ overflowX: "hidden" }}>
            <Intro />
            <Navigation />
            <Row>
                <Col md={6} className="mt-5">
                    <div className="productdetailsImage">
                        <Image
                            src={selectedImage || product.image[0]}
                            alt={product.title}
                            className="imagedisplay"
                            style={{
                                height: "75%",
                                width: "75%",
                                objectFit: "contain",
                                objectPosition: "center",
                            }}
                        />
                    </div>
                    <div className="imageOptionBox mt-3">
                        {product.image.map((image, index) => (
                            <div
                                className="imageOption"
                                style={{
                                    border: selectedImage === image ? "2px solid #15b3bd" : "none",
                                }}
                                onClick={() => handleImageClick(image)}
                            >
                                <Image
                                    src={image}
                                    alt={product.title}
                                    className="optionImage"
                                />
                            </div>
                        ))}
                    </div>
                </Col>
                <Col md={6} className="mt-5">
                    <div className="productDetailsCard ">
                        <div
                            style={{
                                width: "100%",
                                height: "10px",
                                backgroundColor: "#0c1439",
                                marginBottom: "20px",
                            }}
                        ></div>
                        <div
                            className="p-2 m-1 text-dark"
                            style={{
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <div
                                className="text-left"
                                style={{
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                    color: "#616161",
                                }}
                            >
                                {product.category}
                            </div>
                            <div
                                className="text-left"
                                style={{ fontSize: "20px", fontWeight: "bold" }}
                            >
                                {product.title}
                            </div>
                            <br />
                            <div style={{ fontSize: "16px", fontWeight: "bold" }}>
                                Product Description
                            </div>
                            <div>{product.description}</div>
                            <br />
                            <div style={{ fontSize: "16px", fontWeight: "bold" }}>
                                Product Price
                            </div>
                            <div style={{ fontSize: "28px", color: "#616161" }}>
                                {product.price}
                            </div>
                        </div>
                        <div
                            style={{
                                position: "absolute",
                                bottom: "0",
                                width: "100%",
                                textAlign: "center",
                                paddingBottom: "20px",
                                paddingRight: "10px",
                                paddingLeft: "10px",
                            }}
                        >
                            <button
                                type="button"
                                className="btn-order"
                                onClick={() => addToCart(product)}
                            >
                                ADD TO CART
                            </button>
                        </div>
                    </div>
                </Col>
            </Row>
            <ToastContainer
                position="bottom-center"
                autoClose={2000}
                hideProgressBar={false}
                closeOnClick
                pauseOnHover
                draggable
                bodyClassName="toast-body-red"
                style={{ opacity: 0.85 }}
            />
            <Footer />
        </div>
    );
};

export default ProductDetailspage;