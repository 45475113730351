import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import logo from "../assets/logo1.png";
import cartIcon from "../assets/cart-icon.png";
import searchIcon from "../assets/search-icon.png";

const Intro = () => {
  const [searchKeyword, setSearchKeyword] = useState("");
  const navigate = useNavigate();

  const handleSearch = () => {
    navigate(`/search/${searchKeyword}`);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <Container>
      <div className="navContainer">
        <div className="search">
          <input
            type="text"
            placeholder=" Cari produk..."
            value={searchKeyword}
            onChange={(e) => setSearchKeyword(e.target.value)}
            onKeyPress={handleKeyPress}
            className="searchinput"
          />
          <button type="button" className="searchButton" onClick={handleSearch}>
            <img
              src={searchIcon}
              width="18"
              height="18"
              alt="Whatsapp"
              className="marginsearch"
            />
          </button>
        </div>
        <div className="navItem">
          <Link to="/">
            <img src={logo} width="150" height="auto" alt="Logo" />
          </Link>
        </div>
        <div className="btn-cart custom-btn-cart">
          <Link to="/cart">
            <button type="button" className="btn-cart">
              <img
                src={cartIcon}
                width="24"
                height="24"
                alt="Cart"
                className="carticon"
              />
              Cart
            </button>
          </Link>
        </div>
      </div>
    </Container>
  );
};

export default Intro;
