import React, { useState, useEffect } from "react";
import { Card, Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import Navigation from "../components/Navigation";
import Intro from "../components/Intro";
import Footer from "../components/Footer";
import numeral from "numeral";

const Cartpage = () => {
  const [cart, setCart] = useState([]);

  useEffect(() => {
    const savedCart = JSON.parse(localStorage.getItem("cart")) || [];
    setCart(savedCart);
  }, []);

  const removeItem = (productId) => {
    const updatedCart = cart.filter((item) => item.id !== productId);
    setCart(updatedCart);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
  };

  const clearCart = () => {
    setCart([]);
    localStorage.setItem("cart", JSON.stringify([]));
  };

  const handleOrder = () => {
    const itemsInCart = cart.map((item) => `\n- ${item.title}`).join("");
    const totalPrice = calculateTotalPrice();

    const whatsappMessage = `Item:${itemsInCart}\nTotal Price: Rp${numeral(totalPrice).format("0,0")} /day`;

    const whatsappLink = `https://wa.me/6282159876543?text=${encodeURIComponent(
      whatsappMessage
    )}`;

    window.open(whatsappLink, "_blank");
  };

  const calculateTotalPrice = () => {
    let totalPrice = 0;

    cart.forEach((item) => {
      const price = numeral(item.price).value();
      totalPrice += price;
    });

    return totalPrice;
  };

  return (
    <div>
      <Intro />
      <Navigation />
      <br />
      <Container>
        <h2>Your Cart</h2>
        {cart.length === 0 ? (
          <p>Your cart is empty.</p>
        ) : (
          <>
            <Row>
              <Col xs={12} className="d-flex justify-content-end">
                <button
                  style={{
                    marginBottom: "16px",
                  }}
                  className="btn-clearcart"
                  onClick={clearCart}
                >
                  Clear Cart
                </button>
              </Col>
            </Row>
            {cart.map((item) => (
              <Card key={item.id} className="mb-3">
                <Card.Body>
                  <Row>
                    <Col md={3}>
                      <Link to={`/product/${item.id}`}>
                        <Image
                          src={item.image && item.image[0]}
                          alt={item.title}
                          className="imagescart"
                        />
                      </Link>
                    </Col>
                    <Col>
                      <Card.Title>{item.title}</Card.Title>
                      <Card.Text> {item.category}</Card.Text>
                      <Card.Text className="price">
                        Price: Rp{numeral(item.price).format("0,0")}
                      </Card.Text>
                      <button
                        type="button"
                        className="btn-removecart"
                        onClick={() => removeItem(item.id)}
                      >
                        Remove
                      </button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            ))}
            <Row>
              <Col xs={12} className="d-flex justify-content-end">
                <p className="total-price">
                  Total Price: Rp{numeral(calculateTotalPrice()).format("0,0")} /day
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="d-flex justify-content-end">
                <button
                  style={{
                    marginBottom: "16px",
                  }}
                  className="btn-ordercart"
                  onClick={handleOrder}
                >
                  Order via WhatsApp
                </button>
              </Col>
            </Row>
          </>
        )}
      </Container>
      <Footer />
    </div>
  );
};

export default Cartpage;
