import React from "react";
import Intro from "../components/Intro";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import { Container } from "react-bootstrap";

function Aboutpage() {
  return (
    <div>
      <Intro />
      <Navigation />
      <Container>
        <div>About Us</div>
      </Container>
      <Footer />
    </div>
  );
}

export default Aboutpage;
