import Navigation from "./components/Navigation";
import Intro from "./components/Intro";
import Trending from "./components/Trending";
import Products from "./components/Products";
import Slider from "./components/Slider";
import slides from "./components/mock.json";
import Footer from "./components/Footer";

import "./style.css";

function App() {
  return (
    <div>
      <Intro />
      <Navigation />
      <Slider slides={slides} />
      {/*<Trending />*/}
      <Products />
      <Footer />
    </div>
  );
}

export default App;
