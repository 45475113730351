import logo from "../assets/logo2.png";
import { Link } from "react-router-dom";
import instagramIcon from "../assets/instagram-icon.png";
import whatsappIcon from "../assets/whatsapp-icon.png";
import facebookIcon from "../assets/facebook-icon.png";

const Footer = () => {
  return (
    <div className="contentdoang">
      <div className="content-wrap"></div>
      <div id="contact" className="mt-5">
        <div className="wrapper">
          <div className="footer flex-container">
            <div>
              <Link to="/">
                <img src={logo} width="100" height="auto" alt="Logo" />
              </Link>
            </div>
            <div className="footer-section">
              <h2>Information</h2>
              <Link
                to="/about"
                style={{ textDecoration: "none", color: "#FFF" }}
              >
                <p>About Us</p>
              </Link>
            </div>
            <div className="footer-section">
              <h2>Customer Service</h2>
              <Link
                to="https://wa.me/+6282159876543"
                style={{ textDecoration: "none", color: "#FFF" }}
              >
                <p>Contact Us</p>
              </Link>
            </div>
            <div className="footer-section">
              <h2>Order Contact</h2>
              <Link
                to="https://instagram.com"
                style={{ textDecoration: "none", color: "#FFF" }}
              >
                <div className="instagram-section">
                  <img
                    src={instagramIcon}
                    width="30"
                    height="30"
                    alt="Instagram"
                  />
                  <p>@MataLensa</p>
                </div>
              </Link>
              <Link
                to="https://wa.me/+6282159876543"
                style={{ textDecoration: "none", color: "#FFF" }}
              >
                <div className="whatsapp-section mt-2">
                  <img
                    src={whatsappIcon}
                    width="30"
                    height="30"
                    alt="Whatsapp"
                  />
                  <p>082159876543</p>
                </div>
              </Link>
              <Link
                to="https://facebook.com"
                style={{ textDecoration: "none", color: "#FFF" }}
              >
                <div className="facebook-section mt-2">
                  <img
                    src={facebookIcon}
                    width="30"
                    height="30"
                    alt="Facebook"
                  />
                  <p>Mata Lensa</p>
                </div>
              </Link>
            </div>
          </div>
          <p className="copyright">&copy; 2024 Mata Lensa</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
